import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IRequestPayment, IResponseBranchProduct } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataService } from 'src/app/services/data/data.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import {
  InterfaceErrorText,
  InterfaceText,
  PaymentChannel,
  PaymentCurrency,
  PaymentDescription,
  PaymentType,
  PromotionType,
  StorageKey,
} from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-payment-price',
  templateUrl: './payment-price.component.html',
  styleUrls: ['./payment-price.component.scss']
})
export class PaymentPriceComponent implements OnInit {

  constructor(
    private router: Router,
    public commonService: CommonService,
    private dataService: DataService,
  ) { }

  TXT_PAYMENT_PRICE_TITLE = InterfaceText.PAYMENT_PRICE_TITLE;
  TXT_PAYMENT_PRICE_SUBTITLE = InterfaceText.PAYMENT_PRICE_SUBTITLE;
  TXT_PAYMENT_PRICE_BACK_BUTTON = InterfaceText.PAYMENT_PRICE_BACK_BUTTON
  TXT_PAYMENT_PRICE_PRODUCT_NAME = InterfaceText.PAYMENT_PRICE_PRODUCT_NAME;
  TXT_PAYMENT_PRICE_PROMOTION_BUTTON = InterfaceText.PAYMENT_PRICE_PROMOTION_BUTTON;
  TXT_PAYMENT_PRICE_PROMOTION_REMOVE_BUTTON = InterfaceText.PAYMENT_PRICE_PROMOTION_REMOVE_BUTTON;
  TXT_PAYMENT_PRICE_LABEL_DISCOUNT = InterfaceText.PAYMENT_PRICE_LABEL_DISCOUNT;
  TXT_PAYMENT_PRICE_LABEL_VAT = InterfaceText.PAYMENT_PRICE_LABEL_VAT;
  TXT_PAYMENT_PRICE_LABEL_NET = InterfaceText.PAYMENT_PRICE_LABEL_NET;
  TXT_PAYMENT_PRICE_LABEL_CURRENCY = InterfaceText.PAYMENT_PRICE_LABEL_CURRENCY;
  TXT_PAYMENT_PRICE_STANDBY_TEXT = InterfaceText.PAYMENT_PRICE_STANDBY_TEXT;
  TXT_PAYMENT_PRICE_PROMOTION_HEADER = InterfaceText.PAYMENT_PRICE_PROMOTION_HEADER;
  TXT_PAYMENT_PRICE_PROMOTION_FOUND = InterfaceText.PAYMENT_PRICE_PROMOTION_FOUND;

  isOffline: boolean = false; 
  isGettingPrice: boolean = false;
  isErrorPrice: boolean = false;
  checkLabel: string = 'CHECKOUT';

  standbyText: string = InterfaceText.PAYMENT_OPTION_STANDBY_TEXT;
  showCamLoad: boolean = true;
  modalRef: NgbModalRef;
  promoError: boolean = false;
  showKeyboard = false;
  enteredPromo = '';

  isGotDiscount: boolean = false;
  fullPrice: number = 0;
  vatPrice: number = 0;
  discountPrice: number = 0;
  deductedPrice: number = 0;
  vatPercent: number = 0
  totalPrice: number = 0;
  product: IResponseBranchProduct = null;

  // @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;

  ngOnInit(): void {
    this.getProduct();
  }

  async getProduct() {
    this.commonService.isLoading = true;
    this.isGettingPrice = true;

    try {
      this.product = await this.dataService.getBranchProductByCode().toPromise();
      this.setProduct();
    }
    catch(err) {
      this.isErrorPrice = true;
      console.log(JSON.stringify(err));
    }
    finally {
      this.commonService.isLoading = false;
      this.isGettingPrice = false;
    }

  }

  onBack() {
    this.isGotDiscount = false;
    this.discountPrice = 0;
    this.commonService.clearPromotion(true);
    this.setProduct();
    this.router.navigate([AppRoute.PAYMENT_OR_PROMO]);
  }

  setProduct() {

    this.fullPrice = this.product.price / 100;
    this.vatPercent = this.product.vat_percent;
    this.vatPrice = this.fullPrice * this.vatPercent / 100;
    this.deductedPrice = this.fullPrice - this.discountPrice;
    this.totalPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice + this.vatPrice)
    
    this.TXT_PAYMENT_PRICE_PRODUCT_NAME = this.product.product_name;

    const promo_use = localStorage.getItem('promo_use');
    if (promo_use === 'true') {
      console.log('i calculate promo')
      this.isGotDiscount = true;
      this.standbyText = InterfaceText.PROMOTION_FOUND;

      const promo_type = localStorage.getItem('promo_type');
      const promo_amount = Number(localStorage.getItem('promo_amount'));

      if (promo_type === PromotionType.FREE) {
        this.discountPrice = this.fullPrice;
        this.deductedPrice = this.fullPrice - this.discountPrice
        this.vatPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice * this.vatPercent / 100)
        this.totalPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice + this.vatPrice)
      }
      else if (promo_type === PromotionType.AMOUNT) {
        this.discountPrice = promo_amount;
        this.deductedPrice = this.fullPrice - this.discountPrice
        this.vatPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice * this.vatPercent / 100)
        this.totalPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice + this.vatPrice)
      }
      else if (promo_type === PromotionType.PERCENT) {
        console.log('case percent')
        this.discountPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.fullPrice * promo_amount / 100);
        this.deductedPrice = this.fullPrice - this.discountPrice;
        this.vatPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice * this.vatPercent / 100);
        this.totalPrice = this.commonService.roundNumberToTwoDecimalPlaces(this.deductedPrice + this.vatPrice);
      }
      else {
        this.standbyText = InterfaceErrorText.INVALID_PROMOTION_TYPE;
      }

    }
    else {
      this.isGotDiscount = false;
    }

  }

  async onCheckout() {

    const promo_id = localStorage.getItem(StorageKey.PROMO_ID);
    const promo_code = localStorage.getItem(StorageKey.PROMO_CODE);
    const promo_type = localStorage.getItem(StorageKey.PROMO_TYPE);

    if (this.totalPrice === 0 || promo_type === PromotionType.FREE) {

      let payload: IRequestPayment = {
        branch_code: localStorage.getItem(StorageKey.BRANCH_CODE),
        payment_type: PaymentType.FREE,
        channel: PaymentChannel.FREE,
        amount_net: 0,
        amount_vat: 0,
        description: PaymentDescription.DEFAULT,
        amount_discount: Number(this.deductedPrice),
        currency: PaymentCurrency.THB,
        ref_id_1: promo_code,
        ref_id_2: promo_id,
      }
      await this.dataService.getCreatePayment(payload).toPromise();

      let payload1 = { is_used: true };
      const resultPromotion = await this.dataService.updatePromotionById(promo_id, payload1).toPromise();
      if (resultPromotion.statusCode === 200) {
        localStorage.removeItem(StorageKey.PROMO_ID);
        localStorage.removeItem(StorageKey.PROMO_CODE);
        this.router.navigate([AppRoute.PAYMENT_SUCCESS]);
      }
    }
    else if (promo_type === PromotionType.AMOUNT) {
      this.router.navigate(
        [AppRoute.PAYMENT_OPTION],
        {
          queryParams: {
            promo_type: PromotionType.AMOUNT,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        }
      );
    }
    else if (promo_type === PromotionType.PERCENT) {
      this.router.navigate(
        [AppRoute.PAYMENT_OPTION],
        {
          queryParams: {
            promo_type: PromotionType.PERCENT,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        }
      );
    }
    else {
      this.router.navigate(
        [AppRoute.PAYMENT_OPTION],
        {
          queryParams: {
            promo_type: PromotionType.NONE,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        }
      );
    }

    
  }

  doRetry() {
    this.isErrorPrice = false;
    this.getProduct();
  }

  setOnline() {
    this.isOffline = false;
  }

  setOffline() {
    this.isOffline = true;
  }

}
