<div class="top-bar"></div>
<div class="content-wrapper box">
  <div class="flex-container">
    <div class="container centered" *ngIf="paymentTypes.length > 0 && commonService.isLoading === false">
      <div class="row text-center">
        <div *ngFor="let type of mainPaymentTypes; let i = index" class="col-4"
          style="margin-left: auto; margin-right: auto;" (click)="goToPayment(type.code)">
          <div class="card payment-item" style="width:100%;">
            <img class="card-img-top" src="assets/img/{{ type.code }}.png" alt="{{ type.name }}">
            <h3 class="robotomono-font mt-2">{{ type.name }}</h3>
          </div>
        </div>
      </div>
      <div class="row v-center mt-5" *ngIf="subPaymentTypes.length > 0 && commonService.isLoading === false">
        <p class="p-0 m-0">
          <button type="button" class="option-button" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <strong>MORE PAYMENT OPTIONS</strong>
          </button>
        </p>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" style="width: 70%;">
          <div class="card">
            <div class="card-body p-0">
              <div class="row mb-3">
                <div *ngFor="let type of subPaymentTypes; let i = index" class="col-4"
                  style="margin-left: auto; margin-right: auto;" (click)="goToPayment(type.code)">
                  <div class="payment-item">
                    <img class="payment-sub-item img-fluid card-img-top" src="assets/img/{{ type.code }}.png"
                      alt="{{ type.name }}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container centered" *ngIf="paymentTypes.length === 0 && commonService.isLoading === false">
    <div class="row text-center">
      <div class="col-12">
        <img src="assets/img/maintenance.png" alt="" class="img-fluid mb-3" style="width:400px;">
        <h1 class="robotomono-font mt-2">No Payment Option Available</h1>
        <h3 class="robotomono-font">Sorry for your inconvenience.</h3>
      </div>
    </div>
  </div>
</div>
<div class="fixed-logo">
  <img src="assets/img/logo.png" alt="" class="logo-icon">
</div>
<button class="back-button fixed-back" (click)="goToPreviousPage()">
  BACK
</button>
<button class="btn btn-light fixed-exit pin-button" disabled>
  <img src="assets/img/new/star-icon.png" alt="pin" class="pin-icon">
</button>