import { Component } from '@angular/core';

@Component({
  selector: 'app-error-notfound',
  templateUrl: './error-notfound.component.html',
  styleUrls: ['./error-notfound.component.scss']
})
export class ErrorNotfoundComponent {

}
