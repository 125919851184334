<div class="content-wrapper text-center">
   <div class="row mb-2">
    <div class="col-12">
        <h2>Screenlock Configurations</h2>
    </div>
   </div>
    <div class="row">
        <div class="col-12">
            <label for="dslr_endpoint">DSLRBOOTH Endpoint</label>
            <input id="dslr_endpoint" type="text" class="form-control" [(ngModel)]="dslr_endpoint">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="dslr_port">DSLRBOOTH Port</label>
            <input id="dslr_port" type="number" class="form-control" [(ngModel)]="dslr_port">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="dslr_password">DSLRBOOTH Password</label>
            <input id="dslr_password" type="text" class="form-control" [(ngModel)]="dslr_password">
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <button class="btn btn-success btn-block" (click)="onSave()">
                SAVE
            </button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <button class="btn btn-outline-dark btn-block" (click)="onBack()">
                BACK
            </button>
        </div>
    </div>
</div>