import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import PocketBase from 'pocketbase';
import { ApprovalRequestBody, ApproveRequestConfig, PocketResponseStaffUser } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataPocketService } from 'src/app/services/data-pocket/data-pocket.service';
import { DataService } from 'src/app/services/data/data.service';
import {
  HiddenMenuMode,
  InterfaceErrorText,
  InterfaceText,
  PinDialogEvent,
  PinDialogVerifyResult,
  PocketbaseAction,
  RequestApprovalState,
  RequestReason,
  StorageKey,
  TimePeriod,
} from 'src/app/shared/constants/global-config.constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pin-dialog-request',
  templateUrl: './pin-dialog-request.component.html',
  styleUrls: ['./pin-dialog-request.component.scss']
})
export class PinDialogRequestComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private dataPocketService: DataPocketService,
    public commonService: CommonService
  ) { }

  pbClient = new PocketBase(environment.pocket_endpoint);

  TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.HOME_PIN_TITLE;
  TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.HOME_PIN_HEADER;
  TXT_PAYMENT_OPTION_DIALOG_DEL_BUTTON = InterfaceText.PAYMENT_OPTION_DIALOG_DEL_BUTTON;

  typedPin: string = '';
  selectedReason: string = '';
  requestReason = RequestReason;
  RequestApprovalState = RequestApprovalState;
  currentRequestState: RequestApprovalState = RequestApprovalState.STANDBY;
  currentApprovalRequestId: string = '';
  globalTimeout;
  errorMessage: string = 'YOUR REQUEST IS REJECTED!';

  @Input() pinMode: HiddenMenuMode = null;
  @Input() approveRequestConfig: ApproveRequestConfig = null;
  @Output() onSuccessVerify: EventEmitter<PinDialogVerifyResult> = new EventEmitter<PinDialogVerifyResult>();
  @Output() onFailedVerify: EventEmitter<PinDialogVerifyResult> = new EventEmitter<PinDialogVerifyResult>();
  @Output() onCloseDialog: EventEmitter<PinDialogVerifyResult> = new EventEmitter<PinDialogVerifyResult>();

  ngOnInit(): void {
    if (this.pinMode === HiddenMenuMode.NONE) {
      this.TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.SKIP_PAYMENT_HEADER
      this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.PAY_PIN_TITLE;
    }
    else if (this.pinMode === HiddenMenuMode.CONFIG || this.pinMode === HiddenMenuMode.EXIT) {
      this.TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.HOME_PIN_HEADER
      this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.HOME_PIN_TITLE;
    }

    console.log(this.approveRequestConfig);
  }

  async addPIN(num: string) {
    this.typedPin = this.typedPin + num;
    try {

    }
    catch (err) {
      if (err.status === 404) {
        if (this.typedPin.length >= 6) {
          this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceErrorText.USER_NOT_FOUND;
        }
      }
      else {
        this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceErrorText.CONNECTION_ERROR
      }
    }
  }

  clearPIN() {
    this.typedPin = "";
  }

  delPIN() {
    this.typedPin = this.typedPin.slice(0, -1)
  }

  closeDialog() {
    this.onCloseDialog.emit({
      mode: this.pinMode,
      event: PinDialogEvent.CLOSE_DIALOG,
    });
  }

  checkAllowRequest() {
    if (!this.typedPin || !this.selectedReason) {
      return true;
    }
    else {
      return false;
    }
  }

  async submitRequest() {

    try {

      this.commonService.isLoading = true;

      let verifyApiSuccess = false;
      let verifyPocketSuccess = false;

      // 1 - Verify if this user actually allowed to use PIN + Add PIN use to database
      const result = await this.dataService.getPinVerification(this.typedPin).toPromise();
      if (result.statusCode === 200) {
        verifyApiSuccess = true;
      }

      if (verifyApiSuccess) {
        // 2 - Search if PIN actually available on Pocketbase
        const resultPocket: PocketResponseStaffUser = await this.dataPocketService.searchActivePin(this.typedPin).toPromise();

        if (resultPocket.items.length > 0) {
          verifyPocketSuccess = true;
          console.log(resultPocket.items[0].id);

          if (resultPocket.items[0].is_global) {

            // 3.1 - Create Approval Request on Pocketbase - Global User
            this.currentRequestState = RequestApprovalState.APPROVED;
            setTimeout(() => {
              this.onSuccessVerify.emit({
                mode: this.pinMode,
                event: PinDialogEvent.SUCCESS,
              });
            }, TimePeriod.THREE_SEC);

          }
          else {

            const currentDate = this.commonService.getCurrentDate();
            const pinCountResult = await this.dataService.getPinCount(this.typedPin, currentDate, currentDate).toPromise();

            if (pinCountResult.data.count <= this.approveRequestConfig.items[0].value.amount_allow_use_pin_perday) { // not exceed quota

              console.log('not exceed quota', pinCountResult.data.count);

              this.currentRequestState = RequestApprovalState.APPROVED;
              setTimeout(() => {
                this.onSuccessVerify.emit({
                  mode: this.pinMode,
                  event: PinDialogEvent.SUCCESS,
                });
              }, TimePeriod.THREE_SEC);

            }
            else { // quota exceeded

              console.log('quota exceeded', pinCountResult.data.count);

              // 3.2 - Create Approval Request on Pocketbase - Normal User
              const requestPayload: ApprovalRequestBody = {
                branch_id: localStorage.getItem(StorageKey.BRANCH_ID),
                branch_name: localStorage.getItem(StorageKey.BRANCH_CODE),
                request_reason: this.selectedReason,
                request_by: resultPocket.items[0].id,
                usage_today: pinCountResult.data.count,
                limit_today: this.approveRequestConfig.items[0].value.amount_allow_use_pin_perday,
                status: RequestApprovalState.PENDING,
                type: null,
                program: null,
                environment: null
              }
              const resultApprovalRequest: ApprovalRequestBody = await this.dataPocketService.createApprovalReq(requestPayload).toPromise();
              console.log(resultApprovalRequest);

              if (resultApprovalRequest?.id) {

                this.currentApprovalRequestId = resultApprovalRequest.id;
                console.log(`subscribe to realtime: ${environment.pocket_col_approval_req}/${this.currentApprovalRequestId}`)

                await this.pbClient.collection('co_app_users').authWithPassword(
                  environment.pocket_acc,
                  localStorage.getItem(StorageKey.POCKET_KEY)
                );
                this.pbClient.realtime.subscribe(`${environment.pocket_col_approval_req}/${this.currentApprovalRequestId}`, (data) => {
                  
                  console.log(data)
                  if (data.action === PocketbaseAction.UPDATE) {
                    if (data.record.status === RequestApprovalState.APPROVED) { // APPROVED
                      this.currentRequestState = RequestApprovalState.APPROVED;
                      setTimeout(() => {
                        this.onSuccessVerify.emit({
                          mode: this.pinMode,
                          event: PinDialogEvent.SUCCESS,
                        });
                      }, TimePeriod.THREE_SEC);
                    }
                    else if (data.record.status === RequestApprovalState.REJECTED) { // REJECTED
                      this.currentRequestState = RequestApprovalState.REJECTED;
                      this.errorMessage = InterfaceErrorText.REQ_ERROR_REJECTED;
                    }
                    else { //UNKNOWN
                      this.currentRequestState = RequestApprovalState.ERROR;
                      this.errorMessage = InterfaceErrorText.REQ_UNKNOWN_ERROR;
                    }
                  }
                  this.pbClient.realtime.unsubscribe(`${environment.pocket_col_approval_req}/${this.currentApprovalRequestId}`);
                  clearTimeout(this.globalTimeout);
                });

                console.log(this.pbClient.realtime.isConnected);

                this.currentRequestState = RequestApprovalState.PENDING;
                this.globalTimeout = setTimeout(() => {
                  this.currentRequestState = RequestApprovalState.ERROR;
                  this.errorMessage = InterfaceErrorText.REQ_TIMEOUT;
                  console.log('im from timeout');
                }, TimePeriod.THREE_MINUTE);

              }
              else {
                this.currentRequestState = RequestApprovalState.ERROR;
                this.errorMessage = InterfaceErrorText.REQ_UNKNOWN_ERROR;
              }

            }

          }
        }
        else {
          this.currentRequestState = RequestApprovalState.ERROR;
          this.errorMessage = InterfaceErrorText.REQ_UNAUTHORIZED;
        }
      }
      else {
        this.currentRequestState = RequestApprovalState.ERROR;
        this.errorMessage = InterfaceErrorText.REQ_UNAUTHORIZED;
      }

    }
    catch (err) {
      this.currentRequestState = RequestApprovalState.ERROR;
      if (err.error.statusCode === 404) {
        this.errorMessage = String(err.error.message).toUpperCase();
      }
      else {
        this.errorMessage = String(err.error.message).toUpperCase();
      }
    }
    finally {
      this.commonService.isLoading = false;
    }


  }

  backToStandby() {
    if (this.currentRequestState === RequestApprovalState.PENDING) {
      this.pbClient.realtime.unsubscribe(`${environment.pocket_col_approval_req}/${this.currentApprovalRequestId}`);
      clearTimeout(this.globalTimeout);
    }

    this.typedPin = '';
    this.selectedReason = '';
    this.currentRequestState = RequestApprovalState.STANDBY;
    this.currentApprovalRequestId = '';
  }

  chooseReason(reasonId: string) {
    this.selectedReason = reasonId;
    console.log(this.selectedReason);
  }
  
  getReasonFromId() {
    if (this.selectedReason) {
      const label_th = this.requestReason.find((reason) => reason.id === this.selectedReason).label_th;
      const label_en = this.requestReason.find((reason) => reason.id === this.selectedReason).label_en;

      return `${label_en} / ${label_th}`

    }
    else {
      return 'กรุณาเลือกเหตุผล';
    }
  }

}
