import { Location } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';

@Component({
  selector: 'app-error-cannot-get-price',
  templateUrl: './error-cannot-get-price.component.html',
  styleUrls: ['./error-cannot-get-price.component.scss']
})
export class ErrorCannotGetPriceComponent {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  @Output() onRetry: EventEmitter<any> = new EventEmitter();
  goToProceed() {
    this.router.navigate([AppRoute.HOME]);
  }

  doRetry() {
    this.onRetry.emit();
  }

  doBack() {
    this.location.back();
  }

}
