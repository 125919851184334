import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-network-detector',
  templateUrl: './network-detector.component.html',
  styleUrls: ['./network-detector.component.scss']
})
export class NetworkDetectorComponent implements OnInit {

  @Output() onOnline: EventEmitter<any> = new EventEmitter();
  @Output() onOffline: EventEmitter<any> = new EventEmitter();
  
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.handleAppConnectivityChanges();
  }

  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      // handle online mode
      console.log('Online...');
      this.onOnline.emit();
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      // handle offline mode
      console.log('Offline...');
      this.onOffline.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
