import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-not-supported',
  templateUrl: './payment-not-supported.component.html',
  styleUrls: ['./payment-not-supported.component.scss']
})
export class PaymentNotSupportedComponent {

}
