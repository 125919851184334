<app-network-detector (onOnline)="setOnline()" (onOffline)="setOffline()"></app-network-detector>
<app-error-network *ngIf="isOffline"></app-error-network>

<div class="top-bar"></div>
<div class="content-wrapper">
  <div class="row box text-center robotomono-font" style="height: 80%">
    <div class="col-12 justify-content-center align-items-center">
      <div class="card align-middle" style="
          width: 60%;
          margin-left: auto;
          margin-right: auto;
          margin-top: auto;
          margin-bottom: auto;
        ">
        <div class="card-body pt-1 pb-3">
          <div class="row text-center mt-2 mb-2">
            <div class="col-12 p-0">
              <img class="img-fluid" src="assets/img/logo-dot.jpg" alt="" style="width:300px;" />
            </div>
          </div>
          <h2 class="card-title mb-0">{{ TXT_PAYMENT_PRICE_TITLE }}</h2>
          <div class="row text-left mt-42">
            <div class="col-12">
              <div class="row mb-3 text-center">
                <div class="col-12">
                  <hr class="dash">
                </div>
              </div>
              <div class="row">
                <div class="col-7">
                  <h6 class="item-medium">
                    {{ TXT_PAYMENT_PRICE_PRODUCT_NAME }}
                  </h6>
                </div>
                <div class="col-3 text-right" style="color: #464646">
                  <span *ngIf="isGotDiscount"><s>
                      <h2 class="p-0 m-0">{{ fullPrice | number: ".2" }}</h2>
                    </s></span>
                  <span *ngIf="!isGotDiscount">
                    <h2 class="p-0 m-0">{{ fullPrice | number: ".2" }}</h2>
                  </span>
                </div>
                <div class="col-2">
                  <h2 class="p-0 m-0 text-right">
                    {{ TXT_PAYMENT_PRICE_LABEL_CURRENCY }}
                  </h2>
                </div>
              </div>
              <div class="row" *ngIf="isGotDiscount">
                <div class="col-7">
                  <h2 class="item-small p-0 m-0">
                    {{ TXT_PAYMENT_PRICE_LABEL_DISCOUNT }}
                  </h2>
                </div>
                <div class="col-3 text-right">
                  <h2 class="p-0 m-0">
                    -{{ discountPrice | number: ".2" }}
                  </h2>
                </div>
                <div class="col-2">
                  <h2 class="p-0 m-0 text-right">
                    {{ TXT_PAYMENT_PRICE_LABEL_CURRENCY }}
                  </h2>
                </div>
              </div>
              <div class="row" *ngIf="isGotDiscount">
                <div class="col-7">
                </div>
                <div class="col-3 text-right">
                  <h2 class="p-0 m-0">
                    {{ deductedPrice | number: ".2" }}
                  </h2>
                </div>
                <div class="col-2 text-right">
                  <h2 class="p-0 m-0 text-right">
                    {{ TXT_PAYMENT_PRICE_LABEL_CURRENCY }}
                  </h2>
                </div>
              </div>
              <div class="row" [hidden]="vatPrice === 0">
                <div class="col-7">
                  <h6 class="item-small">
                    {{ TXT_PAYMENT_PRICE_LABEL_VAT }} {{ vatPercent }}%
                  </h6>
                </div>
                <div class="col-3 text-right">
                  <h2 class="p-0 m-0">
                    {{ vatPrice | number: '.2' }}
                  </h2>
                </div>
                <div class="col-2">
                  <h2 class="p-0 m-0 text-right">
                    {{ TXT_PAYMENT_PRICE_LABEL_CURRENCY }}
                  </h2>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-12">
                  <hr class="dash">
                </div>
              </div>
              <div class="row item-big mt-3">
                <div class="col-7">
                  <h6 class="item-big">{{ TXT_PAYMENT_PRICE_LABEL_NET }}</h6>
                </div>
                <div class="col-3 text-right">
                  <em>{{ totalPrice | number: ".2" }}</em>
                </div>
                <div class="col-2 text-right">
                  <em>{{ TXT_PAYMENT_PRICE_LABEL_CURRENCY }}</em>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-bottom">

        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <button class="confirm-button" (click)="onCheckout()" [disabled]="commonService.isLoading" [hidden]="isGettingPrice">
          {{ checkLabel }}
        </button>
      </div>
    </div>
  </div>
  <button class="btn btn-light fixed-exit pin-button" disabled>
    <img src="assets/img/new/star-icon.png" alt="pin" class="pin-icon">
  </button>
  <button class="back-button fixed-back" (click)="onBack()">
    BACK
  </button>
</div>
<app-error-cannot-get-price *ngIf="isErrorPrice" (onRetry)="doRetry()"></app-error-cannot-get-price>
<app-loading *ngIf="isGettingPrice || commonService.isLoading"></app-loading>

<ng-template #typepromo let-modal>
  <div class="modal-header robotomono-font orange-bg stroke text-center">
    <h1 class="modal-title" style="
          color: white;
          font-size: 50px;
          margin-left: auto;
          margin-right: auto;
        ">
      {{ TXT_PAYMENT_OPTION_PROMOTION_TYPE_SELECT }}
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title text-center" style="margin-left: auto; margin-right: auto"
      [ngClass]="{'promo-error': promoError}">
      {{ TXT_PAYMENT_OPTION_PROMOTION_TYPE_SELECT_SUBTITLE }}
    </h4>
  </div>
  <div class="modal-body robotomono-font p-5" style="background-color: #ffffff">
    <div class="row text-center type-select">
      <div class="col-6 type-select-item">
        <button class="btn btn-light p-5 mb-5" (click)="openDialog(promotion, 'promotion')">
          <img class="img-fluid type-select-icon" alt="" src="assets/img/type-qr.png">
          <h5>QR Code</h5>
        </button>
      </div>
      <div class="col-6 type-select-item">
        <button class="btn btn-light p-5 mb-5" (click)="openDialog(promotiontext, 'promotiontext')">
          <img class="img-fluid type-select-icon" alt="" src="assets/img/type-code.png">
          <h5>Text Code</h5>
        </button>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px"
          (click)="modal.dismiss('Cross click')">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #promotion let-modal>
  <div class="modal-header robotomono-font orange-bg stroke text-center">
    <h1 class="modal-title" style="
          color: white;
          font-size: 50px;
          margin-left: auto;
          margin-right: auto;
        ">
      {{ TXT_PAYMENT_PRICE_PROMOTION_HEADER }}
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title text-center" style="margin-left: auto; margin-right: auto"
      [ngClass]="{'promo-error': promoError}">
      {{ standbyText }}
    </h4>
  </div>
  <div class="modal-body robotomono-font p-5" style="background-color: #ffffff">
    <div class="row text-center">
      <img class="img-fluid" src="assets/img/load-cam.gif" alt="" style="margin-left: auto; margin-right: auto"
        *ngIf="showCamLoad" />
    </div>
    <div class="row text-center">
      <div class="col-12 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px"
          (click)="modal.dismiss('Cross click')">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #promotiontext let-modal>
  <div class="modal-header robotomono-font orange-bg stroke text-center">
    <h1 class="modal-title" style="
          color: white;
          font-size: 50px;
          margin-left: auto;
          margin-right: auto;
        ">
      {{ TXT_PAYMENT_OPTION_PROMOTION_HEADER }}
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title text-center" style="margin-left: auto; margin-right: auto"
      [ngClass]="{'promo-error': promoError}">
      {{ standbyText }}
    </h4>
  </div>
  <div class="modal-body robotomono-font p-5" style="background-color: #ffffff">
    <div class="row text-center">
      <div class="form-group w-100">
        <input type="text text-center" class="form-control" id="promo_code" placeholder="CODE"
          style="font-size: 30px; border: 1px solid black" [(ngModel)]="enteredPromo" />
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px" (click)="submitPromo()">
          SUBMIT
        </button>
      </div>
    </div>
  </div>
</ng-template>
<app-custom-keyboard *ngIf="showKeyboard" (keyClickEvent)="checkCharacter($event)"></app-custom-keyboard>