<div class="modal-header robotomono-font">
    <h1 class="modal-title text-center" style="margin-left: auto; margin-right: auto;">
      {{ TXT_HOME_PIN_DIALOG_HEADER }}
    </h1>
    <button type="button" class="close modal-close-button" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true" style="color: white;">&times;</span>
    </button>
  </div>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title text-center" style="margin-left: auto; margin-right: auto; color: white;">
      {{ TXT_HOME_PIN_DIALOG_TITLE }}
    </h4>
  </div>
  <div class="modal-body robotomono-font p-5" style="background-color: #ffffff">
    <div class="form-group">
      <input type="password" class="form-control" id="exampleInputPassword1" placeholder="PIN"
        style="font-size: 40px; border: 1px solid black" [(ngModel)]="typed" />
    </div>
    <div class="row text-center">
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('1')">
          1
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('2')">
          2
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('3')">
          3
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('4')">
          4
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('5')">
          5
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('6')">
          6
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('7')">
          7
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('8')">
          8
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('9')">
          9
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px" (click)="clearPIN()">
          RESET
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" (click)="addPIN('0')">
          0
        </button>
      </div>
      <div class="col-4 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px" (click)="delPIN()">
          {{ TXT_PAYMENT_OPTION_DIALOG_DEL_BUTTON }}
        </button>
      </div>
    </div>
  </div>