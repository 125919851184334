<div class="modal-header robotomono-font">
    <h1 class="modal-title text-center" style="margin-left: auto; margin-right: auto;">
        {{ TXT_HOME_PIN_DIALOG_HEADER }}
    </h1>
    <button type="button" class="close modal-close-button" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true" style="color: white;">&times;</span>
    </button>
</div>
<div class="modal-body robotomono-font pl-5 pr-5 pb-5 pt-3" style="background-color: #ffffff">
    <div *ngIf="currentRequestState === RequestApprovalState.STANDBY">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="requestReason">REQUEST REASON</label><br>
                    <div ngbDropdown class="d-inline-block w-100">
                        <button type="button" class="btn btn-outline-dark w-100 reason-button" [ngClass]="selectedReason ? 'regular-control' : 'focus-control'"
                        id="dropdownBasic1" ngbDropdownToggle>
                            {{ getReasonFromId() }}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem class="reason-button"
                            (click)="chooseReason(reason.id)" *ngFor="let reason of requestReason">
                                {{ reason.label_en }} / {{ reason.label_th }}
                            </button>
                        </div>
                    </div>
                </div>         
            </div>
        </div>
        <div class="form-group">
            <label for="staffPin">STAFF PIN</label>
            <input type="password" class="form-control" [ngClass]="typedPin ? 'regular-control' : 'focus-control'"
                id="staffPin" placeholder="PIN" [(ngModel)]="typedPin" name="staffPin" autocomplete="staffPin" />
        </div>
        <div class="row text-center">
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('1')">
                    1
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('2')">
                    2
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('3')">
                    3
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('4')">
                    4
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('5')">
                    5
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('6')">
                    6
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('7')">
                    7
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('8')">
                    8
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('9')">
                    9
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" style="font-size: 20px" (click)="clearPIN()">
                    RESET
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" (click)="addPIN('0')">
                    0
                </button>
            </div>
            <div class="col-4 mt-3">
                <button class="btn btn-light btn-block numpad-button" style="font-size: 20px" (click)="delPIN()">
                    {{ TXT_PAYMENT_OPTION_DIALOG_DEL_BUTTON }}
                </button>
            </div>
            <div class="col-12 text-center mt-4">
                <button class="btn btn-dark btn-block request-button"
                    [disabled]="checkAllowRequest() || commonService.isLoading"
                    (click)="submitRequest()">REQUEST</button>
            </div>
        </div>
    </div>
    <div *ngIf="currentRequestState === RequestApprovalState.PENDING">
        <div class="row">
            <div class="col-7">
                <span class="inline">STATUS: </span><span class="inline" style="color: #272727;">PENDING</span>
            </div>
            <div class="col-5 text-right">
                <small>#{{ currentApprovalRequestId }}</small>
            </div>
            <div class="col-12">
                WAIT FOR APPROVAL
            </div>
            <div class="col-12 loader-box d-flex justify-content-center align-items-center">
                <div class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-dark btn-block request-button" (click)="backToStandby()">
                    CANCEL
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="currentRequestState === RequestApprovalState.APPROVED">
        <div class="row">
            <div class="col-7">
                <span class="inline">STATUS: </span><span class="inline" style="color: green;">APPROVED</span>
            </div>
            <div class="col-5 text-right">
                <small>#{{ currentApprovalRequestId }}</small>
            </div>
            <div class="col-12">
                YOUR REQUEST IS APPROVED!
            </div>
            <div class="col-12 loader-box d-flex justify-content-center align-items-center">
                <img src="assets/img/approve.png" alt="" class="img-fluid" style="height: 100%;">
            </div>
            <div class="col-12">
                <button class="btn btn-dark btn-block request-button">
                    REDIRECTING ...
                </button>
            </div>
        </div>
    </div>
    <div
        *ngIf="currentRequestState === RequestApprovalState.REJECTED || currentRequestState === RequestApprovalState.ERROR">
        <div class="row">
            <div class="col-7">
                <span class="inline">STATUS: </span><span class="inline" style="color: red;">REJECTED</span>
            </div>
            <div class="col-5 text-right">
                <small></small>
            </div>
            <div class="col-12">
                {{ errorMessage }}
            </div>
            <div class="col-12 loader-box d-flex justify-content-center align-items-center">
                <img src="assets/img/decline.png" alt="" class="img-fluid" style="height: 100%;">
            </div>
            <div class="col-12">
                <button class="btn btn-dark btn-block request-button" (click)="backToStandby()">
                    RETRY
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="currentRequestState === RequestApprovalState.NO_NETWORK">
    </div>
</div>
<app-loading *ngIf="commonService.isLoading"></app-loading>