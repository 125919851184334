<app-network-detector (onOnline)="setOnline()" (onOffline)="setOffline()"></app-network-detector>
<app-error-network *ngIf="isOffline"></app-error-network>

<div class="top-bar"></div>
<div class="content-wrapper">
  <div class="flex-container">
    <button class="solid-button pay-button" (click)="goToPrice()">
      {{ TXT_PAYMENT_OPTION_PAY }}
    </button>
    <button class="solid-promo-button mt-5" (click)="goToPromoType()">
      {{ TXT_PAYMENT_OPTION_PROMOTION_BUTTON }}
    </button>
  </div>
</div>

<a (click)="openPIN(content, null)" class="btn btn-light fixed-exit pin-button">
  <img src="assets/img/new/star-icon.png" alt="pin" class="pin-icon">
</a>
<div class="fixed-logo">
  <img src="assets/img/new/sculpture-new.png" alt="" class="logo-icon">
</div>
<button class="back-button fixed-back" (click)="goToHome()">
  {{ TXT_GLOBAL_BACK_BUTTON }}
</button>

<ng-template #content let-modal>
  <app-pin-dialog [pinMode]="currentHiddenMenuMode" *ngIf="usePinDialogType === PinDialogType.REGULAR"
  (onSuccessVerify)="handleSuccessVerify($event)"
    (onFailedVerify)="handleFailedVerify($event)"
    (onCloseDialog)="handleCloseDialog($event)">
  </app-pin-dialog>
  <app-pin-dialog-request [pinMode]="currentHiddenMenuMode" [approveRequestConfig]="approveRequestConfig"
  *ngIf="usePinDialogType === PinDialogType.REQUEST_APPROVE"
  (onSuccessVerify)="handleSuccessVerify($event)"
    (onFailedVerify)="handleFailedVerify($event)"
    (onCloseDialog)="handleCloseDialog($event)">
  </app-pin-dialog-request>
</ng-template>
<app-loading *ngIf="commonService.isLoading"></app-loading>