import { Component } from '@angular/core';

@Component({
  selector: 'app-config-set',
  templateUrl: './config-set.component.html',
  styleUrls: ['./config-set.component.scss']
})
export class ConfigSetComponent {

}
