import { Component } from '@angular/core';

@Component({
  selector: 'app-error-down',
  templateUrl: './error-down.component.html',
  styleUrls: ['./error-down.component.scss']
})
export class ErrorDownComponent {

}
