<app-network-detector (onOnline)="setOnline()" (onOffline)="setOffline()"></app-network-detector>
<app-error-network *ngIf="isOffline"></app-error-network>

<div class="background" longPress (mouseLongPress)="increaseCount()" (click)="checkAndClearCountValue()">
  <img *ngIf="branchInterface !== null" [src]="branchInterface.home_background_image_url" class="stretch" alt="" />
  <img *ngIf="isInterfaceError" src="assets/img/placeholder-updated.jpg" class="stretch" alt="" />
</div>

<ng-template #hiddenMenu let-modal>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title">Hidden Menu</h4>
    <button type="button" class="btn-close btn-solid" aria-label="Close"
      (click)="modal.dismiss('Cross click')">X</button>
  </div>
  <div class="modal-body robotomono-font">
    <div class="row text-center">
      <div class="col-3">
        <div class="card">
          <div class="card-body robotomono-font">
            <h5 class="card-title"><strong>ตั้งค่า</strong></h5>
            <h6 class="card-subtitle mb-2 text-muted"><strong>Setting</strong></h6>
            <button class="btn btn-dark btn-block btn-solid" (click)="goToConfig()">👆</button>
            <small>Admin Only</small>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body robotomono-font">
            <h5 class="card-title"><strong>เปลี่ยนสาขา</strong></h5>
            <h6 class="card-subtitle mb-2 text-muted"><strong>Change Branch</strong></h6>
            <button class="btn btn-dark btn-block btn-solid" (click)="goToLogout()">👆</button>
            <small>Admin Only</small>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body robotomono-font">
            <h2 class="card-title"><strong>ออกจากโปรแกรม</strong></h2>
            <h6 class="card-subtitle mb-2 text-muted"><strong>Exit Screenlock</strong></h6>
            <button class="btn btn-dark btn-block btn-solid" (click)="goToExit()">👆</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light robotomono-font btn-solid"
      (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <app-pin-dialog
    [pinMode]="currentHiddenMenuMode"
    (onSuccessVerify)="handleSuccessVerify($event)"
    (onFailedVerify)="handleFailedVerify($event)"
    (onCloseDialog)="handleCloseDialog($event)"
    >
  </app-pin-dialog>
</ng-template>

<small class="fixed-version"><strong>{{ version }}</strong></small>
<app-loading *ngIf="commonService.isLoading"></app-loading>