<div id="content-wrapper">
    <div class="row box text-center robotomono-font">
        <div class="col-12 justify-content-center my-auto">
            <div class="card qr-card align-middle"
                style="width: 50%; margin-left: auto; margin-right: auto; margin-top: auto;">
                <div class="card-body">
                    <div class="row text-center mt-5">  
                        <div class="col-12 qr-col">
                            <img *ngIf="isQrError" class="img-fluid qr-code" src="assets/img/maintenance.png" alt="" style="width:200px;">
                            <div class="qr-box" *ngIf="OmiseQrUrl">
                                <img class="img-fluid qr-code" [src]="OmiseQrUrl" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="row text-center white-font mt-3" *ngIf="!isQrError && !commonService.isLoading">
                        <div class="col-12">
                            <p><strong>{{ TXT_PAYMENT_SCAN_SCULPTURE }}</strong></p>
                            <p><strong></strong></p>
                            <h4>{{ amountSum | number: '.2'}} {{ TXT_PAYMENT_SCAN_CURRENCY }}</h4>
                        </div>
                    </div>
                    <div class="row text-center white-font mt-3" *ngIf="isQrError">
                        <div class="col-12">
                            <h4>{{ TXT_PAYMENT_SCAN_QR_ERROR_TITLE }}</h4>
                            <p><strong></strong></p>
                            <p><strong>{{ TXT_PAYMENT_SCAN_QR_ERROR_SUBTITLE }}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="!commonService.isLoading">
            <div class="row text-center">
                <div class="col-12">
                    <button class="confirm-button" (click)="onCheck()" [disabled]="isLoading" [hidden]="isQrError">{{ checkLabel }}</button>
                </div>
            </div>
        </div>
        <div class="container mt-3" *ngIf="showMoreOption">
            <div class="row text-center">
                <div class="col-12">
                    <button class="option-button" (click)="goToPaymentOption()" [disabled]="isLoading" [hidden]="commonService.isLoading">MORE PAYMENT OPTIONS</button>
                </div>
            </div>
        </div>
    </div>
</div>
<button class="back-button fixed-back" (click)="onBack()">
    BACK
</button>
<app-loading *ngIf="commonService.isLoading"></app-loading>