export const environment = {
  production: false,
  apiEndpoint: 'https://screenlock-api-dev.sculpturebangkok.com',
  x_app_env: 'DEV',
  x_app_domain: 'SC',
  x_app_id: 'aGUAkLyWVA',
  x_app_secret: 'adbb8df1-84b5-4fc8-8926-1170e5c4c300',
  pocket_endpoint: 'https://app-base.sculpturebangkok.com',
  pocket_acc: 'sculpture.screenlock.v2.client@beedev.in.th',
  pocket_group: 'omise_trx_dev',
  pocket_global_config_group: 'global_config_dev',
  pocket_col_staff_pin: 'co_staff_users',
  pocket_col_approval_req: 'trx_approve_req'
};