import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-custom-keyboard',
  templateUrl: './custom-keyboard.component.html',
  styleUrls: ['./custom-keyboard.component.scss']
})
export class CustomKeyboardComponent {

  isFirstLayer = true;
  isSecondLayer = false;

  @Output() keyClickEvent = new EventEmitter<string>();

  toggleLayer() {
    this.isFirstLayer = !this.isFirstLayer;
    this.isSecondLayer = !this.isSecondLayer;
  }

  onKeyClick(key: string) {
    this.keyClickEvent.emit(key);
  }

  onDeleteClick() {
    this.keyClickEvent.emit('DELETE');
  }

  onEnterClick() {
    this.keyClickEvent.emit('ENTER');
  }

}
