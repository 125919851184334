import { transition, trigger, useAnimation } from '@angular/animations';
import { Component } from '@angular/core';
import { moveFromBottomFade, moveFromTopFade } from 'ngx-router-animations';

import { DisableRightClickService } from './shared/services/disable-right-click.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('moveFromTopFade', [
      transition('welcome => product', useAnimation(moveFromTopFade)),
    ]),
    trigger('moveFromTopFade', [
      transition('welcome => payment-promo', useAnimation(moveFromTopFade)),
    ]),
    trigger('moveFromTopFade', [
      transition('product => payment-option', useAnimation(moveFromTopFade)),
    ]),
    trigger('moveFromBottomFade', [
      transition('product => welcome', useAnimation(moveFromBottomFade)),
    ])
  ]
})
export class AppComponent {

  constructor(
    private rightClickDisable: DisableRightClickService
  ) {}

  title = 'sc-screenlock-v2-app';
  public getState(outlet) {
    return outlet.activatedRouteData.state
  }
}
