import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-promo',
  templateUrl: './payment-promo.component.html',
  styleUrls: ['./payment-promo.component.scss']
})
export class PaymentPromoComponent {

}
