import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {

  constructor(
    private _router: Router
  ) { }


  onBack() {
    this._router.navigate(['/welcome']);
  }

  onNext() {
    this._router.navigate(['/payment-option']);
  }

}
