export const AppRoute = {
    HOME: 'home',
    VERIFICATION: 'verification',
    TERMS: 'terms',
    PRODUCT: 'product',
    PAYMENT_OPTION: 'payment-option',
    PAYMENT_PROMPTPAY: 'payment-promptpay',
    PAYMENT_TRUEMONEY: 'payment-truemoney',
    PAYMENT_LINK: 'payment-link',
    PAYMENT_ALIPAY: 'payment-alipay',
    PAYMENT_NOT_SUPPORTED: 'payment-not-supported',
    PAYMENT_PROMO: 'payment-promo',
    PAYMENT_PRICE: 'payment-price',
    PROMO_TYPE: 'promo-type',
    PAYMENT_OR_PROMO: 'payment-or-promo',
    PAYMENT_SUCCESS: 'payment-success',
    PAYMENT_SUCCESS_UNLOCK: 'payment-success/unlock',
    ERROR_CAMERA: 'error-camera',
    ERROR_NETWORK: 'error-network',
    ERROR_MAINTENANCE: 'error-maintenance',
    ERROR_DOWN: 'error-down',
    ERROR_CLOSED: 'error-closed',
    ERROR_FULLSTORAGE: 'error-fullstorage',
    ERROR_PRINTER_NOTFOUND: 'error-printer-notfound',
    REPORT_LOGVIEW: 'report-logview',
    CONFIG: 'config',
    CONFIG_SET: 'config-set',
    EXIT: 'exit',
    DOWNLOAD_QRCODE: 'download-qrcode',
    ERROR_NOTFOUND: '**'
}