import { Component } from '@angular/core';

@Component({
  selector: 'app-report-logview',
  templateUrl: './report-logview.component.html',
  styleUrls: ['./report-logview.component.scss']
})
export class ReportLogviewComponent {

}
