import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomKeyboardNumberComponent } from './components/custom-keyboard-number/custom-keyboard-number.component';
import { CustomKeyboardComponent } from './components/custom-keyboard/custom-keyboard.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LogoComponent } from './components/logo/logo.component';
import { NetworkDetectorComponent } from './components/network-detector/network-detector.component';
import { PinDialogComponent } from './components/pin-dialog/pin-dialog.component';
import { ConfigSetComponent } from './pages/config-set/config-set.component';
import { ConfigComponent } from './pages/config/config.component';
import { DownloadQrcodeComponent } from './pages/download-qrcode/download-qrcode.component';
import { ErrorCameraComponent } from './pages/error-camera/error-camera.component';
import { ErrorCannotGetPriceComponent } from './pages/error-cannot-get-price/error-cannot-get-price.component';
import { ErrorClosedComponent } from './pages/error-closed/error-closed.component';
import { ErrorDownComponent } from './pages/error-down/error-down.component';
import { ErrorFullstorageComponent } from './pages/error-fullstorage/error-fullstorage.component';
import { ErrorMaintenanceComponent } from './pages/error-maintenance/error-maintenance.component';
import { ErrorNetworkComponent } from './pages/error-network/error-network.component';
import { ErrorNotfoundComponent } from './pages/error-notfound/error-notfound.component';
import { ErrorPrinterNotfoundComponent } from './pages/error-printer-notfound/error-printer-notfound.component';
import { ExitComponent } from './pages/exit/exit.component';
import { HiddenMenuComponent } from './pages/hidden-menu/hidden-menu.component';
import { HomeComponent } from './pages/home/home.component';
import { PaymentAlipayComponent } from './pages/payment-alipay/payment-alipay.component';
import { PaymentFailedComponent } from './pages/payment-failed/payment-failed.component';
import { PaymentLinkComponent } from './pages/payment-link/payment-link.component';
import { PaymentNotSupportedComponent } from './pages/payment-not-supported/payment-not-supported.component';
import { PaymentOptionComponent } from './pages/payment-option/payment-option.component';
import { PaymentOrPromoComponent } from './pages/payment-or-promo/payment-or-promo.component';
import { PaymentPriceComponent } from './pages/payment-price/payment-price.component';
import { PaymentPromoQrComponent } from './pages/payment-promo-qr/payment-promo-qr.component';
import { PaymentPromoTextComponent } from './pages/payment-promo-text/payment-promo-text.component';
import { PaymentPromoComponent } from './pages/payment-promo/payment-promo.component';
import { PaymentPromptpayComponent } from './pages/payment-promptpay/payment-promptpay.component';
import { PaymentSuccessUnlockComponent } from './pages/payment-success-unlock/payment-success-unlock.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PaymentTruemoneyComponent } from './pages/payment-truemoney/payment-truemoney.component';
import { ProductPriceComponent } from './pages/product-price/product-price.component';
import { ProductComponent } from './pages/product/product.component';
import { PromoTypeComponent } from './pages/promo-type/promo-type.component';
import { ReportLogviewComponent } from './pages/report-logview/report-logview.component';
import { TermsComponent } from './pages/terms/terms.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { LongPressDirective } from './shared/directives/long-press.directive';
import { PrepareComponent } from './components/prepare/prepare.component';
import { PinDialogRequestComponent } from './components/pin-dialog-request/pin-dialog-request.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    ExitComponent,
    ProductComponent,
    PaymentOptionComponent,
    PaymentPromptpayComponent,
    ConfigComponent,
    DownloadQrcodeComponent,
    LogoComponent,
    ErrorCameraComponent,
    ErrorNotfoundComponent,
    PaymentTruemoneyComponent,
    ErrorNetworkComponent,
    ErrorMaintenanceComponent,
    ErrorDownComponent,
    ErrorClosedComponent,
    ErrorFullstorageComponent,
    ErrorPrinterNotfoundComponent,
    ReportLogviewComponent,
    PaymentPromoComponent,
    PaymentOrPromoComponent,
    ConfigSetComponent,
    ProductPriceComponent,
    PaymentLinkComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    PinDialogComponent,
    HiddenMenuComponent,
    PaymentPromoQrComponent,
    PaymentPromoTextComponent,
    HomeComponent,
    LongPressDirective,
    CustomKeyboardComponent,
    NetworkDetectorComponent,
    VerificationComponent,
    TermsComponent,
    PaymentPriceComponent,
    PaymentNotSupportedComponent,
    PaymentSuccessUnlockComponent,
    PaymentAlipayComponent,
    ErrorCannotGetPriceComponent,
    LoadingComponent,
    CustomKeyboardNumberComponent,
    PromoTypeComponent,
    PrepareComponent,
    PinDialogRequestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    QRCodeModule,
    BrowserAnimationsModule,
    // ZXingScannerModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
