<div class="content-wrapper">
  <div class="container">
    <a class="fixed-button" (click)="onBack()"
      ><img src="assets/img/close-btn.png" alt="" class="img-fluid"
    /></a>
    <div class="row">
      <div class="col-4">
        <img
          class="img-fluid product-image"
          src="https://via.placeholder.com/480x720/?text=Product"
          alt=""
        />
      </div>
      <div class="col-8">
        <div class="container product-container source-font">
          <div class="row mb-5 text-center">
            <h1><strong>Photo Strips (4 Acts 2 Copies)</strong></h1>
          </div>
          <div class="row">
            <div class="col-5">
              <h2><strong>Tickets</strong></h2>
            </div>
            <div class="col-7">
              <h2>150.00</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <h2><strong>VAT (7%)</strong></h2>
            </div>
            <div class="col-7">
              <h2>10.50</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <h2><strong>Discount</strong></h2>
            </div>
            <div class="col-7">
              <h2>-10.50</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <h2><strong>Totals</strong></h2>
            </div>
            <div class="col-7">
              <h2><s>160.50</s> 150.00</h2>
            </div>
          </div>
          <div class="row mt-5 ml-5">
            <div class="col-12">
                <label class="container ml-3 mt-1" style="font-size: 20px;">I agree to Terms & Condition and Privacy Policy
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
            </div>
          </div>
          <div class="row mt-3 mb-3 ml-3">
            <div class="col-12">
                <span class="container" style="font-size: 15px;">
                  <strong>
                    By clicking'Pay Now', you agree to our term and that you have read our privacy policy.</strong>
                  </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <a class="box" (click)="onNext()">
                <div class="centered btn-font" (click)="onNext()">Pay Now</div>
                  <img src="assets/img/btn-bg.png" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="assets/img/sc-people.png" alt="" class="img-fluid fixed-logo">
  </div>
</div>
