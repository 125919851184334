import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponsePaymentType } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataService } from 'src/app/services/data/data.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import { PaymentType, StorageKey } from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['./payment-option.component.scss']
})
export class PaymentOptionComponent implements OnInit {

  constructor(
    public commonService: CommonService,
    private router: Router,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) { }

  paymentTypes: IResponsePaymentType[] = [];
  mainPaymentTypes: IResponsePaymentType[] = [];
  subPaymentTypes: IResponsePaymentType[] = [];
  promoType: string = null;
  totalPrice: number = 0;
  vatPrice: number = 0;
  discountPrice: number = 0;
  deductedPrice: number = 0;
  showMore: boolean;
  autoPromptpay: boolean;

  public isCollapsed = true;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      this.promoType = params.promo_type;
      this.totalPrice = params.total_price;
      this.vatPrice = params.vat_price;
      this.discountPrice = params.discount_price;
      this.deductedPrice = params.deducted_price;
      this.showMore = params.show_more;
      this.autoPromptpay = params.auto_promptpay;
    }
    );

    if(this.showMore) {
      this.isCollapsed = !this.showMore;
    }
    else {
      this.isCollapsed = true;
    }

    if(this.autoPromptpay === undefined) {
      this.autoPromptpay = true;
    }
    else {
      this.autoPromptpay = false;
    }

    this.getPaymentType();
    
  }

  goToPreviousPage() {
    this.router.navigate([AppRoute.PAYMENT_PRICE],
      {
        queryParams: {
          promo_type: this.promoType,
          total_price: this.totalPrice,
          vat_price: this.vatPrice,
          discount_price: this.discountPrice,
          deducted_price: this.deductedPrice,
        }
      });
  }

  async getPaymentType() {

    try {
      this.commonService.isLoading = true;
      this.paymentTypes = await this.dataService.getPaymentType().toPromise();
      this.mainPaymentTypes = this.paymentTypes.filter(type => type.main === true);
      this.subPaymentTypes = this.paymentTypes.filter(type => type.main === false);

      if(this.mainPaymentTypes.length > 1 || this.subPaymentTypes.length > 0) {
        localStorage.setItem(StorageKey.SHOW_MORE_PAYMENT_OPTION, 'true')
      }
      else {
        localStorage.setItem(StorageKey.SHOW_MORE_PAYMENT_OPTION, 'false')
      }

      this.checkAutoPromptpay();
    }
    catch (err) {

    }
    finally {
      this.commonService.isLoading = false;
    }
  }

  checkAutoPromptpay() {
    console.log(this.mainPaymentTypes.length);
    if(this.autoPromptpay) {
      if(this.mainPaymentTypes.length === 1) {
        if(this.mainPaymentTypes[0].code === PaymentType.PROMPTPAY) {
          this.goToPayment(this.mainPaymentTypes[0].code);
        }
      }
    }
  }

  goToPayment(code: string) {
    if (code === PaymentType.PROMPTPAY) {
      this.router.navigate([AppRoute.PAYMENT_PROMPTPAY],
        {
          queryParams: {
            promo_type: this.promoType,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        });
    }
    else if (code === PaymentType.TRUEMONEY) {
      this.router.navigate([AppRoute.PAYMENT_TRUEMONEY],
        {
          queryParams: {
            promo_type: this.promoType,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        });

    }
    else if (code === PaymentType.ALIPAY) {
      this.router.navigate([AppRoute.PAYMENT_ALIPAY],
        {
          queryParams: {
            promo_type: this.promoType,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        });
    }
    else if (code === PaymentType.LINK) {
      this.router.navigate([AppRoute.PAYMENT_LINK],
        {
          queryParams: {
            promo_type: this.promoType,
            total_price: this.totalPrice,
            vat_price: this.vatPrice,
            discount_price: this.discountPrice,
            deducted_price: this.deductedPrice,
          }
        });
    }
    else {
      this.router.navigate([AppRoute.PAYMENT_NOT_SUPPORTED]);
    }
  }

}
