import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IResponseBranch,
  IResponseBranchProduct,
  IResponseBranchUserInterface,
  IResponseGeneral,
  IResponsePayment,
  IResponsePaymentType,
  IResponsePromotion,
  IResponseReason,
  IResponseSession,
  IResponseSuccess,
  ScreenlockV2PinCount,
} from 'src/app/interfaces/interface';
import { StorageKey } from 'src/app/shared/constants/global-config.constant';
import { environment } from 'src/environments/environment';

type NewType = IResponseSuccess;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAppAllowedGuardHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-app-env': environment.x_app_env,
      'x-app-domain': environment.x_app_domain,
      'x-app-id': environment.x_app_id,
      'x-app-secret': environment.x_app_secret,
    })
    return headers;
  }

  getAppGuardHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem(StorageKey.SESSION_ID)}`,
    })
    console.log(headers);
    return headers;
  }

  getVersion(): Observable<IResponseSuccess> {
    return this.httpClient.get<IResponseSuccess>(
      `${environment.apiEndpoint}/core/version`,
      { headers: this.getAppAllowedGuardHeaders() }
    );
  }

  createAppSession(payload: any): Observable<IResponseSession> {
    return this.httpClient.post<IResponseSession>(
      `${environment.apiEndpoint}/auth/app/session`,
      payload,
      { headers: this.getAppAllowedGuardHeaders() }
    );
  }

  getPinVerification(pin): Observable<IResponseSuccess> {
    let branch_code = localStorage.getItem(StorageKey.BRANCH_CODE);
    return this.httpClient.get<IResponseSuccess>(
      `${environment.apiEndpoint}/auth/verify/pin/${branch_code}/${pin}`,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getPinCount(pin, start, end) {
    let branch_code = localStorage.getItem(StorageKey.BRANCH_CODE);
    return this.httpClient.get<ScreenlockV2PinCount>(
      `${environment.apiEndpoint}/auth/verify/pin/count/${branch_code}/${pin}?start=${start}&end=${end}`,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getQrVerification(branch_code, qr): Observable<IResponseSuccess> {
    return this.httpClient.get<IResponseSuccess>(
      `${environment.apiEndpoint}/auth/verify/qr/${branch_code}/${qr}`,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getReasonList(): Observable<IResponseReason> {
    return this.httpClient.get<IResponseReason>(
      `${environment.apiEndpoint}/application/reason`,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getBranchByCode(branch_code): Observable<IResponseBranch> {
    return this.httpClient.get<IResponseBranch>(
      `${environment.apiEndpoint}/application/branch/code/${branch_code}`,
      { headers: this.getAppAllowedGuardHeaders() }
    );
  }
 
  getBranchById(id): Observable<IResponseBranch> {
    return this.httpClient.get<IResponseBranch>(
      `${environment.apiEndpoint}/application/branch/id/${id}`,
      { headers: this.getAppAllowedGuardHeaders() }
    );
  }

  getBranchProductByCode(): Observable<IResponseBranchProduct> {
    let branch_code = localStorage.getItem(StorageKey.BRANCH_CODE);
    return this.httpClient.get<IResponseBranchProduct>(
      `${environment.apiEndpoint}/application/branch/products/code/${branch_code}`,
      { headers: this.getAppAllowedGuardHeaders() }
    );
  }

  getBranchInterfaceById(id): Observable<IResponseBranchUserInterface> {
    return this.httpClient.get<IResponseBranchUserInterface>(
      `${environment.apiEndpoint}/application/branch/interface/id/${id}`,
      { headers: this.getAppAllowedGuardHeaders() }
    );
  }

  getPaymentInfo(trx_id: string): Observable<IResponsePayment> {
    return this.httpClient.get<IResponsePayment>(
      `${environment.apiEndpoint}/application/payment/${trx_id}`,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getCreatePayment(payload): Observable<IResponsePayment> {
    return this.httpClient.post<IResponsePayment>(
      `${environment.apiEndpoint}/application/payment`,
      payload,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getPromotion(code: string): Observable<IResponsePromotion> {
    return this.httpClient.get<IResponsePromotion>(
      `${environment.apiEndpoint}/promotions/code/${code}?branch_id=${localStorage.getItem(StorageKey.BRANCH_ID)}`,
      { headers: this.getAppGuardHeaders() }
    );
  }

  updatePromotionByCode(code: string, payload: any) {
    return this.httpClient.patch<IResponseGeneral>(
      `${environment.apiEndpoint}/promotions/code/${code}`, payload,
      { headers: this.getAppGuardHeaders() }
    );
  }

  updatePromotionById(id: string, payload: any) {
    return this.httpClient.patch<IResponseGeneral>(
      `${environment.apiEndpoint}/promotions/id/${id}`, payload,
      { headers: this.getAppGuardHeaders() }
    );
  }

  updatePaymentByTrxId(trx_id: string, payload: any) {
    return this.httpClient.patch<IResponsePayment>(
      `${environment.apiEndpoint}/application/payment/${trx_id}`, payload,
      { headers: this.getAppGuardHeaders() }
    );
  }

  getPaymentType() {
    return this.httpClient.get<IResponsePaymentType[]>(
      `${environment.apiEndpoint}/application/payment/types`,
      { headers: this.getAppGuardHeaders() }
    );
  }

}
