import { Component, OnInit } from '@angular/core';
import { StorageKey } from 'src/app/shared/constants/global-config.constant';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  dslr_endpoint: string = null;
  dslr_password: string = null;
  dslr_port: number = null;
  isShowBack: boolean = false;

  ngOnInit(): void {

    if(localStorage.getItem(StorageKey.DSLRBOOTH_ENDPOINT)) {
      this.dslr_endpoint = localStorage.getItem(StorageKey.DSLRBOOTH_ENDPOINT);
    }
    else {
      this.dslr_endpoint = 'http://localhost';
    }

    if(localStorage.getItem(StorageKey.DSLRBOOTH_PORT)) {
      this.dslr_port = parseInt(localStorage.getItem(StorageKey.DSLRBOOTH_PORT));
    }
    else {
      this.dslr_port = 1500;
    }
    
    this.dslr_password = localStorage.getItem(StorageKey.DSLRBOOTH_PASSWORD);
    
  }

  onSave() {

    try {
      if(this.dslr_endpoint) {
        localStorage.setItem(StorageKey.DSLRBOOTH_ENDPOINT, this.dslr_endpoint);
      }
      if(this.dslr_password) {
        localStorage.setItem(StorageKey.DSLRBOOTH_PASSWORD, this.dslr_password);  
      }
      if(this.dslr_port) {
        localStorage.setItem(StorageKey.DSLRBOOTH_PORT, this.dslr_port.toString());
      }
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Configuration saved successfully',
      });
    }
    catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Success',
        text: err.message,
      });
    }
    finally {
      this.isShowBack = true;
    }
    
  }

  onBack() {
    window.history.back();
  }

}
