import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfigSetComponent } from './pages/config-set/config-set.component';
import { ConfigComponent } from './pages/config/config.component';
import { DownloadQrcodeComponent } from './pages/download-qrcode/download-qrcode.component';
import { ErrorCameraComponent } from './pages/error-camera/error-camera.component';
import { ErrorClosedComponent } from './pages/error-closed/error-closed.component';
import { ErrorDownComponent } from './pages/error-down/error-down.component';
import { ErrorFullstorageComponent } from './pages/error-fullstorage/error-fullstorage.component';
import { ErrorMaintenanceComponent } from './pages/error-maintenance/error-maintenance.component';
import { ErrorNetworkComponent } from './pages/error-network/error-network.component';
import { ErrorNotfoundComponent } from './pages/error-notfound/error-notfound.component';
import { ErrorPrinterNotfoundComponent } from './pages/error-printer-notfound/error-printer-notfound.component';
import { ExitComponent } from './pages/exit/exit.component';
import { HomeComponent } from './pages/home/home.component';
import { PaymentAlipayComponent } from './pages/payment-alipay/payment-alipay.component';
import { PaymentLinkComponent } from './pages/payment-link/payment-link.component';
import { PaymentNotSupportedComponent } from './pages/payment-not-supported/payment-not-supported.component';
import { PaymentOptionComponent } from './pages/payment-option/payment-option.component';
import { PaymentOrPromoComponent } from './pages/payment-or-promo/payment-or-promo.component';
import { PaymentPriceComponent } from './pages/payment-price/payment-price.component';
import { PaymentPromoComponent } from './pages/payment-promo/payment-promo.component';
import { PaymentPromptpayComponent } from './pages/payment-promptpay/payment-promptpay.component';
import { PaymentSuccessUnlockComponent } from './pages/payment-success-unlock/payment-success-unlock.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PaymentTruemoneyComponent } from './pages/payment-truemoney/payment-truemoney.component';
import { ProductComponent } from './pages/product/product.component';
import { PromoTypeComponent } from './pages/promo-type/promo-type.component';
import { ReportLogviewComponent } from './pages/report-logview/report-logview.component';
import { TermsComponent } from './pages/terms/terms.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { AppRoute } from './shared/constants/app-route.constant';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: AppRoute.HOME, component: HomeComponent, data: { state: AppRoute.HOME } },
  { path: AppRoute.VERIFICATION, component: VerificationComponent, data: { state: AppRoute.VERIFICATION } },
  { path: AppRoute.TERMS, component: TermsComponent, data: { state: AppRoute.TERMS } },
  { path: AppRoute.PRODUCT, component: ProductComponent, data: { state: AppRoute.PRODUCT } },
  { path: AppRoute.PAYMENT_OPTION, component: PaymentOptionComponent, data: { state: AppRoute.PAYMENT_OPTION } },
  { path: AppRoute.PAYMENT_PROMPTPAY, component: PaymentPromptpayComponent, data: { state: AppRoute.PAYMENT_PROMPTPAY } },
  { path: AppRoute.PAYMENT_TRUEMONEY, component: PaymentTruemoneyComponent, data: { state: AppRoute.PAYMENT_TRUEMONEY } },
  { path: AppRoute.PAYMENT_LINK, component: PaymentLinkComponent, data: { state: AppRoute.PAYMENT_LINK } },
  { path: AppRoute.PAYMENT_ALIPAY, component: PaymentAlipayComponent, data: { state: AppRoute.PAYMENT_ALIPAY } },
  { path: AppRoute.PAYMENT_NOT_SUPPORTED, component: PaymentNotSupportedComponent, data: { state: AppRoute.PAYMENT_NOT_SUPPORTED } },
  { path: AppRoute.PAYMENT_PROMO, component: PaymentPromoComponent, data: { state: AppRoute.PAYMENT_PROMO } },
  { path: AppRoute.PAYMENT_PRICE, component: PaymentPriceComponent, data: { state: AppRoute.PAYMENT_PRICE } },
  { path: AppRoute.PAYMENT_OR_PROMO, component: PaymentOrPromoComponent, data: { state: AppRoute.PAYMENT_OR_PROMO } },
  { path: AppRoute.PROMO_TYPE, component: PromoTypeComponent, data: { state: AppRoute.PROMO_TYPE } },
  { path: AppRoute.PAYMENT_SUCCESS, component: PaymentSuccessComponent, data: { state: AppRoute.PAYMENT_SUCCESS } },
  { path: AppRoute.PAYMENT_SUCCESS_UNLOCK, component: PaymentSuccessUnlockComponent, data: { state: AppRoute.PAYMENT_SUCCESS_UNLOCK } },
  { path: AppRoute.ERROR_CAMERA, component: ErrorCameraComponent, data: { state: AppRoute.ERROR_CAMERA } },
  { path: AppRoute.ERROR_NETWORK, component: ErrorNetworkComponent, data: { state: AppRoute.ERROR_NETWORK } },
  { path: AppRoute.ERROR_MAINTENANCE, component: ErrorMaintenanceComponent, data: { state: AppRoute.ERROR_MAINTENANCE } },
  { path: AppRoute.ERROR_DOWN, component: ErrorDownComponent, data: { state: AppRoute.ERROR_DOWN } },
  { path: AppRoute.ERROR_CLOSED, component: ErrorClosedComponent, data: { state: AppRoute.ERROR_CLOSED } },
  { path: AppRoute.ERROR_FULLSTORAGE, component: ErrorFullstorageComponent, data: { state: AppRoute.ERROR_FULLSTORAGE } },
  { path: AppRoute.ERROR_PRINTER_NOTFOUND, component: ErrorPrinterNotfoundComponent, data: { state: AppRoute.ERROR_PRINTER_NOTFOUND } },
  { path: AppRoute.REPORT_LOGVIEW, component: ReportLogviewComponent, data: { state: AppRoute.REPORT_LOGVIEW } },
  { path: AppRoute.CONFIG, component: ConfigComponent, data: { state: AppRoute.CONFIG }  },
  { path: AppRoute.CONFIG_SET, component: ConfigSetComponent, data: { state: AppRoute.CONFIG_SET }  },
  { path: AppRoute.EXIT, component: ExitComponent, data: { state: AppRoute.EXIT }  },
  { path: `${AppRoute.DOWNLOAD_QRCODE}/:id`, component: DownloadQrcodeComponent, data: { state: AppRoute.DOWNLOAD_QRCODE }  },
  { path: '**', component: ErrorNotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
