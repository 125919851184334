import { Component } from '@angular/core';

@Component({
  selector: 'app-error-fullstorage',
  templateUrl: './error-fullstorage.component.html',
  styleUrls: ['./error-fullstorage.component.scss']
})
export class ErrorFullstorageComponent {

}
