<div class="top-bar"></div>
<div id="content-wrapper">
    <div class="row box text-center robotomono-font">
        <div class="col-12 justify-content-center" style="height: 50vh; margin-top:50px;">
            <div class="card qr-card align-middle" style="width: 80%; margin-left: auto; margin-right: auto;">
                <div class="card-body">
                    <div style="margin-top: 40px;" *ngIf="OmiseAlipayUrl">
                        <h2 class="card-title">{{ TXT_PAYMENT_ALIPAY_TITLE }}</h2>
                        <h3 class="card-text">{{ TXT_PAYMENT_ALIPAY_SUBTITLE }}</h3>
                    </div>
                    <div class="row text-center mt-3 qr-box">
                        <div class="col-6 line-box" *ngIf="OmiseAlipayUrl">
                            <qrcode *ngIf="OmiseAlipayUrl" [qrdata]="OmiseAlipayUrl" [width]="256"
                                [errorCorrectionLevel]="'M'"></qrcode>
                        </div>
                        <div class="col-12 mt-5" *ngIf="!OmiseAlipayUrl">
                            <img *ngIf="isQrError" class="img-fluid qr-code mt-5" src="assets/img/maintenance.png" alt=""
                                style="width:200px;">
                        </div>
                        <div class="col-6">
                            <img *ngIf="OmiseAlipayUrl" class="img-fluid qr-code" src="assets/img/ALIPAY.png" alt=""
                                style="width:100px;">
                        </div>
                    </div>
                    <div class="row text-center mt-3 qr-box">
                        <div class="col-6">
                            <div class="row text-center white-font mt-2" *ngIf="!isQrError && OmiseAlipayUrl">
                                <div class="col-12">
                                    <p><strong>{{ TXT_PAYMENT_SCAN_SCULPTURE }}</strong></p>
                                    <h4>{{ amountSum | number: '.2'}} {{ TXT_PAYMENT_SCAN_CURRENCY }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center white-font mt-3" *ngIf="isQrError">
                            <div class="col-12">
                                <h4>{{ TXT_PAYMENT_SCAN_QR_ERROR_TITLE }}</h4>
                                <p><strong></strong></p>
                                <p><strong>{{ TXT_PAYMENT_SCAN_QR_ERROR_SUBTITLE }}</strong></p>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </div>

                </div>
            </div>
        </div>
        <div class="container" *ngIf="OmiseAlipayUrl">
            <div class="row text-center">
                <div class="col-12">
                    <button class="confirm-button" (click)="onCheck()" [disabled]="commonService.isLoading" [hidden]="isQrError">{{
                        checkLabel }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="back-btn col-12 justify-content-center my-auto">
        <button class="back-button" (click)="onBack()">BACK</button>
    </div>
</div>
<button class="btn btn-light fixed-exit pin-button" disabled>
    <img src="assets/img/new/star-icon.png" alt="pin" class="pin-icon">
</button>
<button class="back-button fixed-back" (click)="onBack()">
    BACK
</button>
<app-loading *ngIf="commonService.isLoading"></app-loading>