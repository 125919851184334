import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponseBranchUserInterface } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataDslrService } from 'src/app/services/data-dslr/data-dslr.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import { InterfaceErrorText, StorageKey } from 'src/app/shared/constants/global-config.constant';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {

  constructor(
    private router: Router,
    private dataDslrService: DataDslrService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService
  ) { }

  retrying = false;
  branchInterface: IResponseBranchUserInterface;

  ngOnInit() {

    this.branchInterface = JSON.parse(localStorage.getItem(StorageKey.BRANCH_INTERFACE));

    this.activatedRoute.queryParams
      .subscribe(params => {
        console.log(params);

        if (params.retrying) {
          this.retrying = JSON.parse(params.retrying);
        }

        console.log(this.retrying); // price
      }
      );
  }

  async touchReady() {
    if (this.dataDslrService.checkDslrBoothConfig()) {
      try {
        this.commonService.isLoading = true;
        const result = await this.dataDslrService.startDslrCountdown().toPromise();
        if (result.IsSuccessful) {
          this.router.navigate([AppRoute.PAYMENT_SUCCESS_UNLOCK]);
        }
        else {
          if (result.ErrorMessage === 'The booth is not on the start screen to start a session') {
            this.router.navigate([AppRoute.PAYMENT_SUCCESS_UNLOCK]);
          }
          else {
            this.showDslrBoothErrorPopup();
          }
        }
      }
      catch (err) {
        this.showDslrBoothErrorPopup();
      }
      finally {
        this.commonService.isLoading = false;
      }
    }
    else {
      this.router.navigate([AppRoute.CONFIG]);
    }
  }

  showDslrBoothErrorPopup() {
    Swal.fire({
      title: InterfaceErrorText.PAYMENT_SUCCESS_DSLR_ERROR_TITLE,
      text: InterfaceErrorText.PAYMENT_SUCCESS_DSLR_ERROR_TEXT,
    })
  }

}
