import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IResponseBranchUserInterface } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataService } from 'src/app/services/data/data.service';
import { VersionService } from 'src/app/services/version/version.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import {
  AppConfig,
  HiddenMenuMode,
  InterfaceText,
  PinDialogVerifyResult,
  StorageKey,
} from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    public commonService: CommonService,
    private dataService: DataService,
    private versionService: VersionService,
    private activatedRoute: ActivatedRoute,
  ) { }

  version = this.versionService.version;
  branchInterface: IResponseBranchUserInterface = null;

  isInterfaceError = false;
  isOffline: boolean = false;

  typed: string = '';
  modalRef: NgbModalRef;
  currentHiddenMenuMode: HiddenMenuMode = HiddenMenuMode.NONE;

  TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.HOME_PIN_TITLE;
  TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.HOME_PIN_HEADER;
  TXT_PAYMENT_OPTION_DIALOG_DEL_BUTTON = InterfaceText.PAYMENT_OPTION_DIALOG_DEL_BUTTON;

  countTimer: any;
  countValue = 0;
  @ViewChild('hiddenMenu') hiddenMenu: any;

  ngOnInit(): void {
    if (!this.commonService.checkSessionExisted()) {
      this.router.navigate([AppRoute.VERIFICATION]);
    }
    else {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.show_config !== undefined && params.show_config === 'true') {
          this.router.navigate([AppRoute.CONFIG]);
        }
      });
      this.getBranchInterface();
    }
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.show_hidden !== undefined && params.show_hidden === 'true') {
        this.openFullscreen();
      }
    });
  }

  goToPaymentOption() {
    this.router.navigate([AppRoute.PAYMENT_OPTION]);
  }

  increaseCount() {
    this.countValue += 1;
    console.log(this.countValue);
  }

  checkAndClearCountValue() {
    console.log('Tap count: ' + this.countValue);

    if (this.countValue === 0) {
      this.goToNext();
    }
    else if (this.countValue >= AppConfig.HIDDEN_MENU_COUNT) {
      this.openFullscreen();
    }
    this.countValue = 0;
  }

  goToNext() {
    this.router.navigate([AppRoute.TERMS]);
  }

  openFullscreen() {
    console.log(this.hiddenMenu)
    this.ngbModal.open(this.hiddenMenu, { centered: true, size: 'lg' });
  }

  goToConfig() {
    this.currentHiddenMenuMode = HiddenMenuMode.CONFIG;
    this.ngbModal.dismissAll();
    this.openPin();
  }

  goToExit() {
    this.currentHiddenMenuMode = HiddenMenuMode.EXIT;
    this.ngbModal.dismissAll();
    this.openPin();
  }

  goToLogout() {
    this.currentHiddenMenuMode = HiddenMenuMode.LOGOUT;
    this.ngbModal.dismissAll();
    this.openPin();
  }

  async getBranchInterface() {
    this.commonService.isLoading = true;
    try {
      this.branchInterface = await this.dataService.getBranchInterfaceById(localStorage.getItem(StorageKey.BRANCH_ID)).toPromise();
      localStorage.setItem(StorageKey.BRANCH_INTERFACE,JSON.stringify(this.branchInterface));
    }
    catch (err) { 
      this.isInterfaceError = true;
    }
    finally {
      this.commonService.isLoading = false;
    }
  }

  @ViewChild('content') content: any;
  openPin() {
    this.modalRef = this.ngbModal.open(this.content);
  }

  handleCloseDialog(data: PinDialogVerifyResult) {
    console.log('Close dialog')
    this.modalRef.dismiss();
  }

  handleSuccessVerify(data: PinDialogVerifyResult) {
    console.log('Success verify')
    if(data.mode === HiddenMenuMode.CONFIG) {
      this.modalRef.close();
      this.router.navigate([AppRoute.CONFIG]);
    }
    else if(data.mode === HiddenMenuMode.EXIT) {
      this.modalRef.close();
      this.router.navigate([AppRoute.EXIT]);
    }
    else if(data.mode === HiddenMenuMode.LOGOUT) {
      this.modalRef.close();
      this.doLogout();
    }
  }

  handleFailedVerify(data: PinDialogVerifyResult) {
    //do nothing yet
    console.log('Failed verify');
  }

  doLogout() {
    this.commonService.isLoading = true;
    localStorage.removeItem(StorageKey.SESSION_ID);
    localStorage.removeItem(StorageKey.BRANCH_ID);
    localStorage.removeItem(StorageKey.POCKET_KEY);
    localStorage.removeItem(StorageKey.POCKETBASE_AUTH);
    localStorage.removeItem(StorageKey.POCKET_TOKEN);
    localStorage.removeItem(StorageKey.PROMO_ID);
    localStorage.removeItem(StorageKey.PROMO_TYPE);
    localStorage.removeItem(StorageKey.PROMO_CODE);
    localStorage.removeItem(StorageKey.PROMO_AMOUNT);
    localStorage.removeItem(StorageKey.PROMO_USE);
    localStorage.removeItem(StorageKey.BRANCH_INTERFACE);
    this.router.navigate([AppRoute.VERIFICATION]);
    this.commonService.isLoading = false;
  }

  setOnline() {
    this.isOffline = false;
  }

  setOffline() {
    this.isOffline = true;
  }

}
