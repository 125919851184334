import { Component } from '@angular/core';

@Component({
  selector: 'app-error-maintenance',
  templateUrl: './error-maintenance.component.html',
  styleUrls: ['./error-maintenance.component.scss']
})
export class ErrorMaintenanceComponent {

}
