import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-promo-text',
  templateUrl: './payment-promo-text.component.html',
  styleUrls: ['./payment-promo-text.component.scss']
})
export class PaymentPromoTextComponent {

}
