<div class="first-layer">
    <div class="numpad-box">
        <div>
            <button (click)="onKeyClick('1')">1</button>
            <button (click)="onKeyClick('2')">2</button>
            <button (click)="onKeyClick('3')">3</button>
        </div>
        <div>
            <button (click)="onKeyClick('4')">4</button>
            <button (click)="onKeyClick('5')">5</button>
            <button (click)="onKeyClick('6')">6</button>
        </div>
        <div>
            <button (click)="onKeyClick('7')">7</button>
            <button (click)="onKeyClick('8')">8</button>
            <button (click)="onKeyClick('9')">9</button>
        </div>
        <div>
            <button class="small-text" (click)="onResetClick()" >RESET</button>
            <button (click)="onKeyClick('0')">0</button>
            <button class="small-text" (click)="onDeleteClick()">DEL</button>
        </div>       
    </div>
    <div>
        <button class="special wide mt-3 robotomono-font" (click)="onEnterClick()">
            SUBMIT
        </button>
    </div>
</div>