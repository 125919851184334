import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import {
  HiddenMenuMode,
  InterfaceErrorText,
  InterfaceText,
  PinDialogEvent,
  PinDialogVerifyResult,
} from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-pin-dialog',
  templateUrl: './pin-dialog.component.html',
  styleUrls: ['./pin-dialog.component.scss']
})
export class PinDialogComponent {

  constructor(
    private dataService: DataService,
  ) { }

  TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.HOME_PIN_TITLE;
  TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.HOME_PIN_HEADER;
  TXT_PAYMENT_OPTION_DIALOG_DEL_BUTTON = InterfaceText.PAYMENT_OPTION_DIALOG_DEL_BUTTON;

  typed: string = '';

  @Input() pinMode: HiddenMenuMode = null;
  @Output() onSuccessVerify: EventEmitter<PinDialogVerifyResult> = new EventEmitter<PinDialogVerifyResult>();
  @Output() onFailedVerify: EventEmitter<PinDialogVerifyResult> = new EventEmitter<PinDialogVerifyResult>();
  @Output() onCloseDialog: EventEmitter<PinDialogVerifyResult> = new EventEmitter<PinDialogVerifyResult>();

  ngOnInit(): void {
    if (this.pinMode === HiddenMenuMode.NONE) {
      this.TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.PAY_PIN_HEADER
      this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.PAY_PIN_TITLE;
    }
    else if (this.pinMode === HiddenMenuMode.CONFIG || this.pinMode === HiddenMenuMode.EXIT) {
      this.TXT_HOME_PIN_DIALOG_HEADER = InterfaceText.HOME_PIN_HEADER
      this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceText.HOME_PIN_TITLE;
    }
  }

  async addPIN(num: string) {
    this.typed = this.typed + num;
    try {
      const result = await this.dataService.getPinVerification(this.typed).toPromise();
      if (result.statusCode === 200) {
        this.onSuccessVerify.emit({
          mode: this.pinMode,
          event: PinDialogEvent.SUCCESS,
        });
      }
      else {
        this.onFailedVerify.emit({
          mode: this.pinMode,
          event: PinDialogEvent.SUCCESS,
        });
      }

    }
    catch (err) {
      if (err.status === 404) {
        if (this.typed.length >= 6) {
          this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceErrorText.USER_NOT_FOUND;
        }
      }
      else {
        this.TXT_HOME_PIN_DIALOG_TITLE = InterfaceErrorText.CONNECTION_ERROR
      }
    }
  }

  clearPIN() {
    this.typed = "";
  }

  delPIN() {
    this.typed = this.typed.slice(0, -1)
  }

  closeDialog() {
    this.onCloseDialog.emit({
      mode: this.pinMode,
      event: PinDialogEvent.CLOSE_DIALOG,
    });
  }

}
