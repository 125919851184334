import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-promo-qr',
  templateUrl: './payment-promo-qr.component.html',
  styleUrls: ['./payment-promo-qr.component.scss']
})
export class PaymentPromoQrComponent {

}
