import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovalRequestBody } from 'src/app/interfaces/interface';
import { GlobalProgramInfo, StorageKey } from 'src/app/shared/constants/global-config.constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataPocketService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getPocketbaseHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization': `${localStorage.getItem(StorageKey.POCKET_TOKEN)}`,
      'Content-Type': 'application/json',
    })
    console.log(headers);
    return headers;
  }

  async loginPocketbase() {
    const result = await this.httpClient.post<any>(
      `${environment.pocket_endpoint}/api/collections/co_app_users/auth-with-password`,
      {
        identity: environment.pocket_acc,
        password: localStorage.getItem(StorageKey.POCKET_KEY)
      }
    ).toPromise();
    console.log(result);
    localStorage.setItem(StorageKey.POCKET_TOKEN, result.token);
    return result;
  }

  getTransactionIdByChargeId(charge_id: string) {
    return this.httpClient.get<any>(
      `${environment.pocket_endpoint}/api/collections/${environment.pocket_group}/records?filter=(charge_id='${charge_id}')`,
      { headers: this.getPocketbaseHeader() }
    );
  }

  getTransactionIdByLinkId(link_id: string) {
    return this.httpClient.get<any>(
      `${environment.pocket_endpoint}/api/collections/${environment.pocket_group}/records?filter=(link_id='${link_id}')`,
      { headers: this.getPocketbaseHeader() }
    );
  }

  getPaymentByTrxId(trx_id: string) {
    return this.httpClient.get<any>(
      `${environment.pocket_endpoint}/api/collections/${environment.pocket_group}/records?filter=(id='${trx_id}')`,
      { headers: this.getPocketbaseHeader() }
    );
  }

  getGlobalConfig(key: string) {
    return this.httpClient.get<any>(
      `${environment.pocket_endpoint}/api/collections/${environment.pocket_global_config_group}/records?filter=(key='${key}')`,
      { headers: this.getPocketbaseHeader() }
    );
  }

  searchActivePin(pin: string) {

    const env = environment.production ? 'production' : 'development';
    const program = GlobalProgramInfo.CODE;

    let queryString = `pin='${pin}'`;
    queryString = `${queryString} %26%26 environment='${env}'`;
    queryString = `${queryString} %26%26 program='${program}'`;
    queryString = `${queryString} %26%26 active=true`;

    return this.httpClient.get<any>(
      `${environment.pocket_endpoint}/api/collections/${environment.pocket_col_staff_pin}/records?filter=(${queryString})`,
      { headers: this.getPocketbaseHeader() }
    );

  }

  createApprovalReq(payload: ApprovalRequestBody) {

    const env = environment.production ? 'production' : 'development';
    const program = GlobalProgramInfo.CODE;

    payload.environment = env;
    payload.type = 'sculpture'
    payload.program = program;

    return this.httpClient.post<any>(
      `${environment.pocket_endpoint}/api/collections/${environment.pocket_col_approval_req}/records`,
      payload,
      { headers: this.getPocketbaseHeader() }
    );
  }

}
