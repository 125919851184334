import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-success-unlock',
  templateUrl: './payment-success-unlock.component.html',
  styleUrls: ['./payment-success-unlock.component.scss']
})
export class PaymentSuccessUnlockComponent {

}
