import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';

@Component({
  selector: 'app-error-network',
  templateUrl: './error-network.component.html',
  styleUrls: ['./error-network.component.scss']
})
export class ErrorNetworkComponent {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  firstCount: number = 0;
  secondCount: number = 0;
  thirdCount: number = 0;

  FIRST_COUNT_CLICK = 5;
  FIRST_COUNT_RESET = 20;

  SECOND_COUNT_CLICK = 14;
  SECOND_COUNT_RESET = 20;

  THIRD_COUNT_CLICK = 19;
  THIRD_COUNT_RESET = 20;


  goToProceed() {
    this.router.navigate([AppRoute.HOME]);
  }

  doBack() {
    this.location.back();
  }

  onClickTitle() {
    if(this.firstCount === this.FIRST_COUNT_RESET) {
      this.firstCount = 0;
    }
    else if(this.firstCount === 5 && this.secondCount === 14) {
      this.thirdCount = this.thirdCount + 1;
      if(this.firstCount === this.FIRST_COUNT_CLICK && this.secondCount === this.SECOND_COUNT_CLICK && this.thirdCount === this.THIRD_COUNT_CLICK) {
        this.router.navigate([AppRoute.EXIT]);
      }
    }
    else {
      this.firstCount = this.firstCount + 1;
    }
  }

  onClickSubTitle() {
    if(this.firstCount === 5) {
      if(this.secondCount === this.SECOND_COUNT_RESET) {
        this.secondCount = 0;
      }
      else {
        this.secondCount = this.secondCount + 1;
      }
    }
  }

}
