import { Component } from '@angular/core';

@Component({
  selector: 'app-error-printer-notfound',
  templateUrl: './error-printer-notfound.component.html',
  styleUrls: ['./error-printer-notfound.component.scss']
})
export class ErrorPrinterNotfoundComponent {

}
