import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponseDslrStart } from 'src/app/interfaces/interface';
import { StorageKey } from 'src/app/shared/constants/global-config.constant';

@Injectable({
  providedIn: 'root'
})
export class DataDslrService {

  constructor(
    private httpClient: HttpClient
  ) { }

  checkDslrBoothConfig(): boolean {
    if(
      !localStorage.getItem(StorageKey.DSLRBOOTH_ENDPOINT) || 
      !localStorage.getItem(StorageKey.DSLRBOOTH_PASSWORD) || 
      !localStorage.getItem(StorageKey.DSLRBOOTH_PORT)) {
      return false;
    }
    else {
      return true;
    }
  }

  startDslrCountdown(mode: string = 'print'): Observable<IResponseDslrStart> {
    const dslrEndpoint = localStorage.getItem(StorageKey.DSLRBOOTH_ENDPOINT);
    const dslrPassword = localStorage.getItem(StorageKey.DSLRBOOTH_PASSWORD);
    const dslrPort = localStorage.getItem(StorageKey.DSLRBOOTH_PORT);
    
    return this.httpClient.get<IResponseDslrStart>(`${dslrEndpoint}:${dslrPort}/api/start?mode=print&password=${dslrPassword}`); 
  }

}
