<div class="top-bar"></div>
<div id="content-wrapper">
    <div class="row box text-center robotomono-font">
        <div class="col-12 justify-content-center">
            <div class="card qr-card align-middle" style="width: 80%; margin-left: auto; margin-right: auto;">
                <div class="card-body">
                    <div *ngIf="isPhoneNumberNotProvided">
                        <h2 class="card-title">{{ TXT_PAYMENT_TRUEMONEY_TITLE }}</h2>
                        <h3 class="card-text">{{ TXT_PAYMENT_TRUEMONEY_SUBTITLE }}</h3>
                    </div>
                    <div *ngIf="OmiseTrueMoneyUrl && !isPhoneNumberNotProvided" style="margin-top: 100px;">
                        <h2 class="card-title">{{ TXT_PAYMENT_TRUEMONEY_TITLE_AFTER }}</h2>
                        <h3 class="card-text">{{ TXT_PAYMENT_TRUEMONEY_SUBTITLE_AFTER }}</h3>
                    </div>
                    <div class="form-group phone-number-box mt-5" *ngIf="isPhoneNumberNotProvided">
                        <input type="text" class="form-control phone-number-text" id="phone_number" placeholder=""
                            [(ngModel)]="enteredPhoneNumber" [disabled]="true" />
                    </div>
                    <div class="row text-center mt-3 qr-box">
                        <div class="col-6 line-box" *ngIf="OmiseTrueMoneyUrl && !isPhoneNumberNotProvided">
                            <qrcode *ngIf="OmiseTrueMoneyUrl" [qrdata]="OmiseTrueMoneyUrl" [width]="250"
                                [errorCorrectionLevel]="'M'"></qrcode>
                        </div>
                        <div class="col-12" *ngIf="!OmiseTrueMoneyUrl && !isPhoneNumberNotProvided">
                            <img *ngIf="isQrError && !isPhoneNumberNotProvided" class="img-fluid qr-code"
                                src="assets/img/maintenance.png" alt="" style="width:200px;">
                        </div>
                        <div class="col-6" *ngIf="OmiseTrueMoneyUrl">
                            <img *ngIf="OmiseTrueMoneyUrl" class="img-fluid qr-code" src="assets/img/TRUEMONEY.png"
                                alt="" style="width:100px;">
                                <div class="row text-center">
                                   <div class="col-12">
                                    <h5><strong><em>{{ enteredPhoneNumber | mask: '000-000-0000' }}</em></strong></h5>
                                   </div>
                                </div>
                        </div>
                    </div>
                    <div class="row text-center mt-3 qr-box">
                        <div class="col-6">
                            <div class="row text-center white-font mt-2" *ngIf="!isQrError && OmiseTrueMoneyUrl">
                                <div class="col-12">
                                    <p><strong>{{ TXT_PAYMENT_SCAN_SCULPTURE }}</strong></p>
                                    <h4>{{ amountSum | number: '.2'}} {{ TXT_PAYMENT_SCAN_CURRENCY }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </div>
                    <div class="row text-center white-font mt-3" *ngIf="isQrError">
                        <div class="col-12">
                            <h4>{{ TXT_PAYMENT_SCAN_QR_ERROR_TITLE }}</h4>
                            <p><strong></strong></p>
                            <p><strong>{{ TXT_PAYMENT_SCAN_QR_ERROR_SUBTITLE }}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="OmiseTrueMoneyUrl">
            <div class="row text-center">
                <div class="col-12">
                    <button class="confirm-button" (click)="onCheck()" [disabled]="commonService.isLoading" [hidden]="isQrError">{{
                        checkLabel }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<button class="btn btn-light fixed-exit pin-button" disabled>
    <img src="assets/img/new/star-icon.png" alt="pin" class="pin-icon">
</button>
<button class="back-button fixed-back" (click)="onBack()">
    BACK
</button>
<app-loading *ngIf="commonService.isLoading"></app-loading>
<app-custom-keyboard-number *ngIf="isPhoneNumberNotProvided" (keyClickEvent)="checkCharacter($event)">
</app-custom-keyboard-number>