<div class="first-layer" *ngIf="isFirstLayer">
  <div>
    <button (click)="onKeyClick('1')">1</button>
    <button (click)="onKeyClick('2')">2</button>
    <button (click)="onKeyClick('3')">3</button>
    <button (click)="onKeyClick('4')">4</button>
    <button (click)="onKeyClick('5')">5</button>
    <button (click)="onKeyClick('6')">6</button>
    <button (click)="onKeyClick('7')">7</button>
    <button (click)="onKeyClick('8')">8</button>
    <button (click)="onKeyClick('9')">9</button>
    <button (click)="onKeyClick('0')">0</button>
    <button class="special" (click)="onDeleteClick()">DEL</button>
  </div>
  <div>
    <button (click)="onKeyClick('Q')">Q</button>
    <button (click)="onKeyClick('W')">W</button>
    <button (click)="onKeyClick('E')">E</button>
    <button (click)="onKeyClick('R')">R</button>
    <button (click)="onKeyClick('T')">T</button>
    <button (click)="onKeyClick('Y')">Y</button>
    <button (click)="onKeyClick('U')">U</button>
    <button (click)="onKeyClick('I')">I</button>
    <button (click)="onKeyClick('O')">O</button>
    <button (click)="onKeyClick('P')">P</button>
  </div>
  <div>
    <button (click)="onKeyClick('A')">A</button>
    <button (click)="onKeyClick('S')">S</button>
    <button (click)="onKeyClick('D')">D</button>
    <button (click)="onKeyClick('F')">F</button>
    <button (click)="onKeyClick('G')">G</button>
    <button (click)="onKeyClick('H')">H</button>
    <button (click)="onKeyClick('J')">J</button>
    <button (click)="onKeyClick('K')">K</button>
    <button (click)="onKeyClick('L')">L</button>
  </div>
  <div>
    <button (click)="onKeyClick('Z')">Z</button>
    <button (click)="onKeyClick('X')">X</button>
    <button (click)="onKeyClick('C')">C</button>
    <button (click)="onKeyClick('V')">V</button>
    <button (click)="onKeyClick('B')">B</button>
    <button (click)="onKeyClick('N')">N</button>
    <button (click)="onKeyClick('M')">M</button>
    <button class="special wide" (click)="onEnterClick()">SUBMIT</button>
  </div>
</div>