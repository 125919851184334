import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-custom-keyboard-number',
  templateUrl: './custom-keyboard-number.component.html',
  styleUrls: ['./custom-keyboard-number.component.scss']
})
export class CustomKeyboardNumberComponent {

  @Output() keyClickEvent = new EventEmitter<string>();


  onKeyClick(key: string) {
    this.keyClickEvent.emit(key);
  }

  onDeleteClick() {
    this.keyClickEvent.emit('DELETE');
  }

  onEnterClick() {
    this.keyClickEvent.emit('ENTER');
  }

  onResetClick() {
    this.keyClickEvent.emit('RESET');
  }

}
