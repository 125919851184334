import { Component } from '@angular/core';

@Component({
  selector: 'app-download-qrcode',
  templateUrl: './download-qrcode.component.html',
  styleUrls: ['./download-qrcode.component.scss']
})
export class DownloadQrcodeComponent {

}
