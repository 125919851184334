import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IResponsePromotion } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataService } from 'src/app/services/data/data.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import {
  AppConfig,
  InterfaceErrorText,
  InterfaceText,
  PromotioCodeType,
  StorageKey,
} from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-promo-type',
  templateUrl: './promo-type.component.html',
  styleUrls: ['./promo-type.component.scss']
})
export class PromoTypeComponent {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private dataService: DataService,
    public commonService: CommonService,
  ) { }

  TXT_GLOBAL_BACK_BUTTON = InterfaceText.GLOBAL_BACK_BUTTON;

  modalRef: NgbModalRef;
  isOffline: boolean = false;
  showCamLoad: boolean = true;
  showKeyboard = false;
  promoError: boolean = false;
  enteredPromo = '';
  standbyText: string = InterfaceText.PAYMENT_OPTION_STANDBY_TEXT;
  isGotDiscount: boolean = false;
  closeResult = '';

  openDialog(content, mode: string) {
    if (mode === 'promotion') {
      this.ngbModal.open(content);
    }
    else if (mode === 'promotiontext') {
      this.ngbModal.open(content).result.then(
        (result) => {
          console.log(`Closed with: ${result}`)
        },
        (reason) => {
          this.showKeyboard = false;
          console.log(`Dismissed ${this.getDismissReason(reason)}`)
        },
      );;
      this.showKeyboard = true;
    }
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeDialog() {
    this.ngbModal.dismissAll();
    this.showKeyboard = false;
    this.showCamLoad = true;
  }

  goToBack() {
    this.router.navigate([AppRoute.PAYMENT_OR_PROMO])
  }

  async scanSuccessHandler(resultString: string) {
    this.checkPromo(resultString);
  }

  async checkPromo(resultString) {
    try {
      this.commonService.isLoading = true;
      const promotion = await this.dataService.getPromotion(resultString).toPromise();
      console.log(promotion);

      if (promotion.statusCode === 200) {
        this.processReadyPromotion(promotion, resultString);
      }
      else {
        this.processNotReadyPromotion(promotion);
      }

    }
    catch (err) {
      console.log(err);
      this.standbyText = InterfaceErrorText.CONNECTION_ERROR;
      this.promoError = true;
      this.resetPromoError();
    }
    finally {
      this.commonService.isLoading = false;
    }
  }

  isValidBranchForPromo(promotion: IResponsePromotion): boolean {
    if (Number(promotion.data.branch_id) === Number(localStorage.getItem(StorageKey.BRANCH_ID))) { // Branch ID and Promo Branch ID is same *valid
      return true;
    }
    else if (promotion.data.branch_id === null) { // Promo Branch ID is null *must check further

      if (promotion.data.branch_allow) { // Promo Branch Allow is not null (maybe multiple branch) *must check further
        const found_branch = promotion.data.branch_allow.find(branch => branch.branch_id === Number(localStorage.getItem(StorageKey.BRANCH_ID)));
        if (found_branch) { // Branch ID is found in Promo Branch Allow *valid
          return true;
        }
        else { // Branch ID is not found in Promo Branch Allow *not valid
          return false;
        }
      }
      else { // Promo Branch Allow is null (all branch) *valid
        return true;
      }

    }
    else { // Branch ID and Promo Branch ID is not same *not valid
      return false;
    }
  }

  processReadyPromotion(promotion: IResponsePromotion, resultString: string) {

    if (Number(promotion.data.branch_id) === Number(localStorage.getItem(StorageKey.BRANCH_ID)) || promotion.data.branch_id === null) {

      let isValidBranch = this.isValidBranchForPromo(promotion);

      if (!isValidBranch) { // Branch not valid for promo, throw not found
        this.standbyText = InterfaceErrorText.PROMOTION_NOT_FOUND;
        this.promoError = true;
        this.resetPromoError();
      }
      else { // Branch valid for promo, check promo type

        if (promotion.data.code_type === PromotioCodeType.DYNAMIC || promotion.data.code_type === PromotioCodeType.STATIC) {
          this.processBranchValidPromo(promotion, resultString);
        }
        else {
          this.standbyText = InterfaceErrorText.PROMOTION_NOT_FOUND;
          this.promoError = true;
          this.resetPromoError();
        }
        
      }

    }
    else {
      this.standbyText = InterfaceErrorText.PROMOTION_NOT_FOUND;
      this.promoError = true;
      this.resetPromoError();
    }

  }

  processNotReadyPromotion(promotion: IResponsePromotion) {
    if (promotion.statusCode === 20010) {
      this.standbyText = InterfaceErrorText.PROMOTION_NOT_FOUND;
      this.promoError = true;
      this.resetPromoError();
    }
    else if (promotion.statusCode === 20020 || promotion.statusCode === 20021) {
      this.standbyText = InterfaceErrorText.PROMOTION_ALREADY_USED;
      this.promoError = true;
      this.resetPromoError();
    }
    else if (promotion.statusCode === 20030 || promotion.statusCode === 20031) {
      this.standbyText = InterfaceErrorText.PROMOTION_REVOKED;
      this.promoError = true;
      this.resetPromoError();
    }
    else if (promotion.statusCode === 20040 || promotion.statusCode === 20041) {
      this.standbyText = InterfaceErrorText.PROMOTION_EXPIRED;
      this.promoError = true;
      this.resetPromoError();
    }
    else if (promotion.statusCode === 20070) {
      this.standbyText = InterfaceErrorText.PROMOTION_EXCEED;
      this.promoError = true;
      this.resetPromoError();
    }
  }

  processBranchValidPromo(promotion: IResponsePromotion, resultString: string) {
    if (promotion.data.code_type === PromotioCodeType.DYNAMIC) {
      if (!promotion.data.is_used) {
        this.setSuccessPromotion(promotion, resultString);
      }
      else {
        this.standbyText = InterfaceErrorText.PROMOTION_ALREADY_USED;
        this.promoError = true;
        this.resetPromoError();
      }
    }
    else if (promotion.data.code_type === PromotioCodeType.STATIC) {
      if (promotion.data.code_used < promotion.data.code_amount) {
        this.setSuccessPromotion(promotion, resultString);
      }
      else {
        this.standbyText = InterfaceErrorText.PROMOTION_QUOTA_EXCEED;
        this.promoError = true;
        this.resetPromoError();
      }
    }
  }

  setSuccessPromotion(promotion: IResponsePromotion, resultString: string) {
    if (Number(localStorage.getItem(StorageKey.BRANCH_ID)) === promotion.data.branch_id || promotion.data.branch_id === null) {

      if (!promotion.data.is_used) {
        this.isGotDiscount = true;
        this.standbyText = InterfaceText.PROMOTION_FOUND;
        localStorage.setItem(StorageKey.PROMO_ID, promotion.data.id.toString());
        localStorage.setItem(StorageKey.PROMO_CODE, resultString);
        localStorage.setItem(StorageKey.PROMO_TYPE, promotion.data.promo_type);
        localStorage.setItem(StorageKey.PROMO_AMOUNT, promotion.data.promo_amount.toString());
        localStorage.setItem(StorageKey.PROMO_USE, 'true');
        this.ngbModal.dismissAll();
        this.showKeyboard = false;
        this.enteredPromo = '';
        this.router.navigate([AppRoute.PAYMENT_PRICE])

      }
    }
    else {
      this.standbyText = InterfaceErrorText.PROMOTION_NOT_FOUND;
      this.promoError = true;
      this.resetPromoError();
    }
  }

  resetPromoError() {
    setTimeout(() => {
      this.standbyText = InterfaceText.PAYMENT_OPTION_STANDBY_TEXT;
      this.promoError = false;
      this.enteredPromo = '';
    }, AppConfig.PROMOTION_WARNING_SEC);
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.showCamLoad = false;
  }

  onCamerasNotFound(event) {
    this.showCamLoad = true;
  }

  setOnline() {
    this.isOffline = false;
  }

  setOffline() {
    this.isOffline = true;
  }

  checkCharacter(event: string) {
    if (event === 'ENTER') {
      this.submitPromo();
    }
    else if (event === 'DELETE') {
      this.delCharacter();
    }
    else {
      this.addCharacter(event);
    }

  }


  addCharacter(char: string) {
    this.enteredPromo = this.enteredPromo + char;
    console.log('TYPING PROMO: ', this.enteredPromo);
  }

  delCharacter() {
    this.enteredPromo = this.enteredPromo.slice(0, -1)
  }

  submitPromo() {
    console.log('SUBMITING PROMO: ', this.enteredPromo);
    this.checkPromo(this.enteredPromo);
  }

  dismissAllModal() {
    this.ngbModal.dismissAll()
    this.showKeyboard = false;
  }

}
