import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import PocketBase from 'pocketbase';
import { IRequestPayment, IResponsePocketBasePayment } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataPocketService } from 'src/app/services/data-pocket/data-pocket.service';
import { DataService } from 'src/app/services/data/data.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import {
  InterfaceErrorText,
  InterfaceText,
  OmiseStatusKey,
  OmiseStatusName,
  PaymentChannel,
  PaymentCurrency,
  PaymentDescription,
  PaymentType,
  PocketbaseAction,
  StorageKey,
} from 'src/app/shared/constants/global-config.constant';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-alipay',
  templateUrl: './payment-alipay.component.html',
  styleUrls: ['./payment-alipay.component.scss']
})
export class PaymentAlipayComponent {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    private dataPocketService: DataPocketService,
    public commonService: CommonService
  ) { }

  TXT_PAYMENT_SCAN_SCULPTURE = InterfaceText.PAYMENT_SCAN_SCULPTURE;
  TXT_PAYMENT_ALIPAY_TITLE = InterfaceText.PAYMENT_ALIPAY_TITLE
  TXT_PAYMENT_ALIPAY_SUBTITLE = InterfaceText.PAYMENT_ALIPAY_SUBTITLE
  TXT_PAYMENT_SCAN_QR_ERROR_TITLE = InterfaceErrorText.PAYMENT_SCAN_QR_ERROR_TITLE;
  TXT_PAYMENT_SCAN_QR_ERROR_SUBTITLE = InterfaceErrorText.PAYMENT_SCAN_QR_ERROR_SUBTITLE;
  TXT_PAYMENT_SCAN_CURRENCY = InterfaceText.PAYMENT_SCAN_CURRENCY

  promoType: string = null;
  totalPrice: number = 0;
  vatPrice: number = 0;
  discountPrice: number = 0;
  deductedPrice: number = 0;

  amountNet: string = null;
  amountVat: string = null;
  amountSum: number = 0;
  amountCountDown: number = 0;

  PocketbaseTrxId: string = null;
  SculptureTrxId: string = null;
  SculpturePaymentId: string = null;
  OmiseChargeId: string = null;
  OmiseAlipayUrl: string = null;

  isQrError: boolean = false;

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.promoType = params.promo_type;
      this.totalPrice = params.total_price;
      this.vatPrice = params.vat_price;
      this.discountPrice = params.discount_price;
      this.deductedPrice = params.deducted_price;

      this.getAlipayQR();

    }
    );
    this.amountSum = this.totalPrice;
  }

  async getAlipayQR() {
    this.commonService.isLoading = true;
    try {
      let payload: IRequestPayment = {
        branch_code: localStorage.getItem(StorageKey.BRANCH_CODE),
        payment_type: PaymentType.ALIPAY,
        channel: PaymentChannel.OMISE,
        amount_net: Number(this.deductedPrice),
        amount_vat: Number(this.vatPrice),
        description: PaymentDescription.DEFAULT,
        amount_discount: Number(this.discountPrice),
        currency: PaymentCurrency.THB,
      }
      const result = await this.dataService.getCreatePayment(payload).toPromise();

      this.SculptureTrxId = result.trx_id;
      this.SculpturePaymentId = result.id.toString();
      this.OmiseChargeId = result.ref_id_2;
      this.OmiseAlipayUrl = result.ref_id_3;
      console.log(this.OmiseAlipayUrl);
      if (this.OmiseAlipayUrl === null) {
        this.isQrError = true;
      }
      else {
        setTimeout(() => {
          this.subscribeToCheckPaid();
        }, 3000);
      }

    }
    catch (err) {
      this.isQrError = true;
      console.log(err);
    }
    finally {
      this.commonService.isLoading = false;
    }

  }

  pbClient = new PocketBase(environment.pocket_endpoint);
  async subscribeToCheckPaid() {
    await this.dataPocketService.loginPocketbase();
    console.log('just login to pocketbase');
    console.log('charge id for pocketbase', this.OmiseChargeId)

    console.log('waiting for 3 seconds')
    await this.commonService.sleep(3000);

    console.log('getting pocketbase trx')
    const trx = await this.dataPocketService.getTransactionIdByChargeId(this.OmiseChargeId).toPromise();

    if (trx.items.length > 0) {
      this.PocketbaseTrxId = trx.items[0].id;

      await this.pbClient.collection('co_app_users').authWithPassword(
        environment.pocket_acc,
        localStorage.getItem(StorageKey.POCKET_KEY)
      );

      console.log('pocketbase subscribing to transaction: ALIPAY');
      this.pbClient.realtime.subscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`, (data) => {
        console.log(JSON.stringify(data));
        if (data.action === PocketbaseAction.UPDATE) {
          if (data.record.key === OmiseStatusKey.COMPLETE) {
            this.updatePaymentDone();
            this.pbClient.realtime.unsubscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`);
            this.goToSuccess();
          }
        }

      });
    }
    else {
      //window.location.reload();
    }

  }

  async updatePaymentDone() {
    this.commonService.isLoading = true;
    try {
      let payload1 = { is_paid: true }
      await this.dataService.updatePaymentByTrxId(this.SculptureTrxId, payload1).toPromise();
      if (localStorage.getItem(StorageKey.PROMO_ID) !== null) {
        let payload3: any = { is_used: true, payment_id: this.SculpturePaymentId }
        await this.dataService.updatePromotionById(localStorage.getItem(StorageKey.PROMO_ID), payload3).toPromise();
      }

    }
    catch (err) {
      console.log(JSON.stringify(err));
      if (err.status === 400) {
        console.log('payment already updated, just to make sure');
      }
    }
    finally {
      this.commonService.isLoading = false;
    }
  }

  goToSuccess() {
    this.router.navigate([AppRoute.PAYMENT_SUCCESS]);
  }

  onBack() {
    if (!this.isQrError) {
      this.showBackWarningPopup();
    }
    else {
      this.pbClient.realtime.unsubscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`);
      this.location.back();
    }

  }

  showBackWarningPopup() {
    Swal.fire({
      icon: 'warning',
      title: InterfaceText.PAYMENT_SCAN_PAYMENT_CANCEL_TITLE_EN,
      html: InterfaceText.PAYMENT_SCAN_PAYMENT_CANCEL_EN,
      showDenyButton: true,
      confirmButtonText: InterfaceText.PAYMENT_SCAN_PAYMENT_CANCEL_STAY_EN,
      denyButtonText: InterfaceText.PAYMENT_SCAN_PAYMENT_CANCEL_BACK_EN,
    }).then((result) => {
      if (result.isConfirmed) {
        //do nothing because user want to stay
      } else if (result.isDenied) {
        this.pbClient.realtime.unsubscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`);
        this.location.back();
      }
    })
  }

  onCheck() {
    console.log("checking");
    this.checkPaymentIsPaid();
  }

  async showUnknowErrorPopup() {
    const isConfirmed = await this.commonService.showUnknowErrorPopupAndConfirm();
    if (!isConfirmed) {
      console.log('unsubscribing');
      this.pbClient.realtime.unsubscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`);
      this.location.back();
    }
  }

  checkLabel: string = InterfaceText.PAYMENT_SCAN_CONFIRM_BUTTON;
  async checkQR() {
    try {
      this.commonService.isLoading = true;
      this.checkLabel = InterfaceText.PAYMENT_SCAN_CONFIRM_LOADING;

      const pocketBaseResult: IResponsePocketBasePayment = await this.dataPocketService.getPaymentByTrxId(this.PocketbaseTrxId).toPromise();
      if (pocketBaseResult.items.length > 0) {
        if (pocketBaseResult.items[0].status === OmiseStatusName.SUCCESS) {
          this.updatePaymentDone();
          this.pbClient.realtime.unsubscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`);
          this.goToSuccess();
        }
        else {
          this.commonService.showPaymentNotPaidPopup();
        }
      }
    }
    catch (err) {
      this.commonService.isLoading = false;
      this.checkLabel = InterfaceText.PAYMENT_SCAN_CONFIRM_BUTTON;
      console.log(JSON.stringify(err))
    }
    finally {
      this.commonService.isLoading = false;
      this.checkLabel = InterfaceText.PAYMENT_SCAN_CONFIRM_BUTTON;
    }
  }

  async checkPaymentIsPaid() {
    try {
      this.commonService.isLoading = true;
      this.checkLabel = InterfaceText.PAYMENT_SCAN_CONFIRM_LOADING;

      const payment = await this.dataService.getPaymentInfo(this.SculptureTrxId).toPromise();
      if(payment){
        if(payment.is_paid) {
          this.updatePaymentDone();
          this.pbClient.realtime.unsubscribe(`${environment.pocket_group}/${this.PocketbaseTrxId}`);
          this.goToSuccess();
        }
        else {
          this.commonService.showPaymentNotPaidPopup();
        }
      }
      else {

      }
    }
    catch (err) {
      this.commonService.isLoading = false;
      this.checkLabel = InterfaceText.PAYMENT_SCAN_CONFIRM_BUTTON;
      console.log(JSON.stringify(err))
    }
    finally {
      this.commonService.isLoading = false;
      this.checkLabel = InterfaceText.PAYMENT_SCAN_CONFIRM_BUTTON;
    }
  }


}
