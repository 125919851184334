import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import { InterfaceText, PaymentType, StorageKey } from 'src/app/shared/constants/global-config.constant';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private router: Router
  ) { }

  isLoading = false;

  checkSessionExisted(): boolean {
    console.log(localStorage.getItem(StorageKey.SESSION_ID));
    if (localStorage.getItem(StorageKey.SESSION_ID) === null || localStorage.getItem(StorageKey.SESSION_ID) === undefined) {
      return false;
    }
    else {
      return true;
    }
  }

  showWarningPopup(title: string, text: string) {
    let timerInterval
    Swal.fire({
      icon: 'warning',
      title: title,
      text: text,
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        timerInterval = setInterval(() => {
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  showSuccessPopup(title: string, text: string) {
    let timerInterval
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        timerInterval = setInterval(() => {
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  showPaymentNotPaidPopup() {
    let timerInterval
    Swal.fire({
      title: InterfaceText.PAYMENT_SCAN_NOT_PAID_TITLE,
      html: InterfaceText.PAYMENT_SCAN_NOT_PAID_TEXT,
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    })
  }

  showUnknowErrorPopupAndConfirm(): Promise<boolean> {
    return Swal.fire({
      icon: 'warning',
      title: InterfaceText.PAYMENT_SCAN_PAYMENT_UNKNOWN_TITLE,
      html: InterfaceText.PAYMENT_SCAN_PAYMENT_UNKNOWN,
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      } else if (result.isDenied) {
        return false
      }
      return false; // add this line to return false by default
    })
  }



  clearPromotion(include_use: boolean = false) {
    localStorage.removeItem(StorageKey.PROMO_TYPE);
    localStorage.removeItem(StorageKey.PROMO_CODE);
    localStorage.removeItem(StorageKey.PROMO_AMOUNT);

    if (include_use) {
      localStorage.removeItem(StorageKey.PROMO_USE);
    }

  }

  roundNumberToTwoDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  goToPayment(code: string) {
    if (code === PaymentType.PROMPTPAY) {
      this.router.navigate([AppRoute.PAYMENT_PROMPTPAY]);
    }
    else if (code === PaymentType.TRUEMONEY) {
      this.router.navigate([AppRoute.PAYMENT_TRUEMONEY]);
    }
    else if (code === PaymentType.LINK) {
      this.router.navigate([AppRoute.PAYMENT_LINK]);
    }
    else {
      this.router.navigate([AppRoute.PAYMENT_NOT_SUPPORTED]);
    }
  }

  getCurrentDate(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
