import { Component } from '@angular/core';

@Component({
  selector: 'app-error-closed',
  templateUrl: './error-closed.component.html',
  styleUrls: ['./error-closed.component.scss']
})
export class ErrorClosedComponent {

}
