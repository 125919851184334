import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { DataService } from 'src/app/services/data/data.service';
import { VersionService } from 'src/app/services/version/version.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import { AppAction, StorageKey } from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  constructor(
    private router: Router,
    private dataService: DataService,
    private commonService: CommonService,
    private versionService: VersionService
  ) { }

  public isCollapsed = true;

  version = this.versionService.version;

  isError = false;
  isInvalid = false;

  branch_code: string = null;
  branch_key: string = null;

  ngOnInit(): void {
    if(this.commonService.checkSessionExisted()) {
      this.router.navigate([AppRoute.HOME]);
    }
  }

  async doVerification() {
    try {
      const payload = { 
        branch_code: this.branch_code,
        key: this.branch_key,
        action: AppAction.APP_NEW_SESSION,
        done: true
      }
      const result = await this.dataService.createAppSession(payload).toPromise();
      console.log('session_id', result.session_id);

      localStorage.setItem(StorageKey.SESSION_ID, result.session_id);
      localStorage.setItem(StorageKey.BRANCH_CODE, this.branch_code);
      localStorage.setItem(StorageKey.POCKET_KEY, result.pocket_key);
      localStorage.setItem(StorageKey.BRANCH_ID, result.branch_id.toString());
      this.commonService.showSuccessPopup('Verification Success', 'You can now use the app.');
      this.router.navigate([AppRoute.HOME]);
    }
    catch(err) {
      if(err.status === 403) {
        this.isInvalid = true;
      }
      else {
        this.isError = true;
      }
    }
    finally {
      this.commonService.isLoading = false;
    }
  }

}
