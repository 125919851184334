import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private ngbModalConfig: NgbModalConfig
  ) { 

    ngbModalConfig.backdrop = 'static';
		ngbModalConfig.keyboard = false;

  }

  modalRef: NgbModalRef;
  @ViewChild('termTab') content;

  ngAfterViewInit(): void {
    this.openModal();    
  }

  openModal() {
    this.modalRef = this.ngbModal.open(this.content, {  scrollable: true, centered: true, size: 'xl', windowClass: 'width-80' });
  }

  onDecline() {
    this.modalRef.close();
    this.router.navigate([AppRoute.HOME]);
  }

  onAccept() {
    this.modalRef.close();
    this.router.navigate([AppRoute.PAYMENT_OR_PROMO]);
  }

}
