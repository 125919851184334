<div class="overlay source-font">
    <div class="flex-container">
        <img class="warning-img" src="assets/img/crash.png" alt="" (click)="goToProceed()">
        <h5 class="warning-caption" (click)="onClickTitle()">OOPS! SOMETHING WENT WRONG</h5>
        <h1 class="warning-caption" (click)="onClickSubTitle()">NO NETWORK CONNECTION, PLEASE CONTACT STAFF.</h1>
    </div>
</div>

<div class="fixed-logo">
    <small style="color:white;" [hidden]="firstCount === 0 && secondCount === 0 && thirdCount === 0">
        {{ firstCount }}-{{ secondCount }}-{{ thirdCount }}
    </small>
</div>