<div class="overlay source-font">
    <div class="flex-container">
        <img class="warning-img" src="assets/img/crash.png" alt="" (click)="goToProceed()">
        <h1 class="warning-caption">OOPS! SOMETHING WENT WRONG</h1>
        <h5 class="warning-caption">PRICE DATA CANNOT BE RETRIEVED, PLEASE CONTACT STAFF.</h5>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-dark mr-3" (click)="doRetry()">RETRY</button>
                <button class="btn btn-dark mr-3" (click)="doBack()">BACK</button>
            </div>
        </div>
    </div>
</div>