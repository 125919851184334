<div class="content-wrapper">
  <p>
    <button type="button" class="btn btn-dark btn-lg" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
     START VERIFICATION PROCESS
    </button>
  </p>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="card">
      <div class="card-body">
       <div class="row mb-3">
        <div class="col-12">
          <label for="branch_code">Branch Code</label>
          <input id="branch_code" type="text" class="form-control" [(ngModel)]="branch_code">
        </div>
       </div>
       <div class="row mb-3">
        <div class="col-12">
          <label for="branch_key">Branch Key</label>
          <input id="branch_key" type="text" class="form-control" [(ngModel)]="branch_key">
        </div>
       </div>
       <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-success btn-md btn-block" (click)="doVerification()">🔐 VERIFY</button>
          <small *ngIf="isInvalid" class="warning-text">Invalid credential</small>
          <small *ngIf="isError" class="warning-text">Cannot verify</small>
        </div>
       </div>
      </div>
    </div>
  </div>
</div>
<small class="fixed-version"><strong>{{ version }}</strong></small>
